import React, { useContext, useEffect } from "react"

import { COMPANY_SIDEBAR_MENU_STEP } from "../../../../constants/sidebar-menu"
import { COMPANY_CHANGE_PASSWORD_MENU } from "../../../../constants/profile-menu"

import { GlobalAuthContext } from "../../../../context/authContext"

import ChangePassword from "../../../../components/change-password/change-password"
import CompanyProfileLayout from "../../../../components/layout/company-profile"

const CompanySettingsPage = ({ location }) => {
  const { state } = useContext(GlobalAuthContext)
  useEffect(() => {}, [state.user, state?.isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <CompanyProfileLayout
      menu={COMPANY_CHANGE_PASSWORD_MENU}
      sidebarMenu={COMPANY_SIDEBAR_MENU_STEP}
      activeSidebar="Settings"
      active="Change Password"
      title="Change Password"
      location={location}
    >
      <ChangePassword />
    </CompanyProfileLayout>
  )
}

export default CompanySettingsPage
