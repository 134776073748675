import React, { useContext, useEffect, useState } from "react"

import { navigate } from "gatsby"
import queryString from "query-string"

import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import Spinner from "../components/spinner/spinner"
import PasswordReset from "../components/password-reset/password-reset"
import { GlobalAuthContext } from "../context/authContext"

const PasswordResetPage = ({ location }) => {
  const { token } = queryString.parse(location.search)
  const [loading, setLoading] = useState(true)

  const { state } = useContext(GlobalAuthContext)

  useEffect(() => {
    if (!localStorage?.token) {
      setLoading(false)
    } else {
      navigate("/")
    }
  }, [state?.isAuthenticated, state?.hasProfile])

  if (loading) return <Spinner />

  if (token && token !== null) {
    return (
      <Layout noVerification>
        <SEO title="Reset Password" robot_no_index />
        <PasswordReset token={token} />
      </Layout>
    )
  } else {
    if (typeof window !== "undefined") {
      navigate("/")
    }
    return <Spinner />
  }
}

export default PasswordResetPage
