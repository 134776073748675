import React, { useEffect, useContext, useState } from "react"
import { Container } from "react-bootstrap"

import BreadcrumbComponent from "../components/breadcrumb/breadcrum"
import { FETCH_JOB_DETAILS } from "../components/job/job-actions"
import { FETCH_COMPANY_DETAILS_DATA } from "../components/shared-actions/fetch-company-details"
import { CANDIDATE_PERSONAL_DATA_FETCH } from "../components/candidate-profile/actions/personalAction"
import { GlobalAuthContext } from "../context/authContext"

import Layout from "../components/layout/layout"
import Spinner from "../components/spinner/spinner"
import SEO from "../components/seo"
import JobDetail from "../components/job/job-detail"

const JobDetailPage = ({ slug }) => {
  const [data, setData] = useState({})
  const getJobData = async () => {
    setData({})
    const data = await FETCH_JOB_DETAILS(slug)
    setData(data)
  }
  const pages = [{ link: "/", name: "Home" }]

  const { state, dispatch } = useContext(GlobalAuthContext)
  useEffect(() => {
    if (data?.id?.toString() !== slug.toString()) {
      getJobData()
    }
    if (!!!state?.profile?.country && state?.user?.company?.id) {
      FETCH_COMPANY_DETAILS_DATA(dispatch, "COMPANY_PROFILE_UPDATE")
    } else if (
      state?.user?.candidate?.id &&
      !!!state?.profile?.avatar?.startsWith("https")
    ) {
      CANDIDATE_PERSONAL_DATA_FETCH(
        state?.user?.id,
        null,
        () => {},
        () => {},
        dispatch
      )
    }
  }, [slug, state?.isAuthenticated])
  return (
    <Layout noVerification={true}>
      {data?.id ? (
        <>
          <SEO
            title={
              data?.title +
              " - " +
              data?.company?.organization_name +
              " - " +
              data?.company?.industry_name +
              " - WDN"
            }
          />
          <BreadcrumbComponent
            styles="mb-0"
            currentPage={{
              link: "/",
              name:
                data?.title +
                " - " +
                data?.company?.organization_name +
                " - " +
                data?.company?.industry_name,
            }}
            pages={pages}
          />
          <JobDetail job_data={data} setData={setData} />
        </>
      ) : (
        <Spinner />
      )}
    </Layout>
  )
}

export default JobDetailPage
