import React, { useState, useEffect, useContext } from "react"

import { COMPANY_SIDEBAR_MENU_STEP } from "../../../constants/sidebar-menu"
import { COMPANY_NOTIFICATION_MENU } from "../../../constants/profile-menu"

import { GlobalAuthContext } from "../../../context/authContext"

import { GET_COMPANY_NOTIFICATIONS } from "../../../components/company-profile/notification/notifications-actions"

import Spinner from "../../../components/spinner/spinner"
import CompanyProfileLayout from "../../../components/layout/company-profile"
import Notifications from "../../../components/notification/notification"

const NotificationsPage = ({ location }) => {
  const [notifications, setNotifications] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)

  const { state } = useContext(GlobalAuthContext)

  useEffect(() => {
    setLoading(true)
    if (state.user && state.user.role === 3 && state.hasProfile) {
      GET_COMPANY_NOTIFICATIONS(setNotifications, setLoading, currentPage)
    }
  }, [currentPage, state.user, state.hasProfile]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CompanyProfileLayout
      menu={COMPANY_NOTIFICATION_MENU}
      sidebarMenu={COMPANY_SIDEBAR_MENU_STEP}
      activeSidebar="Notifications"
      active="Notifications"
      title="Notifications"
      location={location}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Notifications
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          notifications={notifications}
        />
      )}
    </CompanyProfileLayout>
  )
}
export default NotificationsPage
