import Placeholder from "../../images/placeholder.jpg"

const aboutData = [
  {
    id: 226,
    date: "2021-01-28T08:54:13",
    date_gmt: "2021-01-28T08:54:13",
    guid: {
      rendered: "https://dev.wdnexuswp.com/?page_id=226",
    },
    modified: "2023-06-16T06:02:36",
    modified_gmt: "2023-06-16T06:02:36",
    slug: "about",
    status: "publish",
    type: "page",
    link: "https://wdnexuswp.com/about/",
    title: {
      rendered: "About",
    },
    content: {
      rendered: "",
      protected: false,
    },
    excerpt: {
      rendered: "",
      protected: false,
    },
    author: 1,
    featured_media: 0,
    parent: 0,
    menu_order: 0,
    comment_status: "closed",
    ping_status: "closed",
    template: "",
    meta: [],
    acf: {
      seo_metadata: [
        {
          title: "About - WDN",
          meta_keywords:
            "Workers\u2019 Directory Nexus, WDN, Connect Companies with Candidates, Job Finder, Companies For Job, Human Resource Companies Nepal, Find Job In Less Time, Hiring At Low Cost",
          description:
            "Workers\u2019 Directory Nexus (WDN) connects Companies with Candidates within a bit of time and at a low cost.",
          meta_image:
            "https://wdnexuswp.com/wp-content/uploads/2022/01/1200x627-1.jpg",
          facebook_title: "About - WDN",
          facebook_description:
            "Workers\u2019 Directory Nexus (WDN) connects Companies with Candidates within a little time and at a low cost. ",
          facebook_image:
            "https://wdnexuswp.com/wp-content/uploads/2022/01/1200x630-1.jpg",
          twitter_title: "About - WDN",
          twitter_description:
            "Workers\u2019 Directory Nexus (WDN) connects Companies with Candidates within a little time and at a low cost. ",
          twitter_image:
            "https://wdnexuswp.com/wp-content/uploads/2022/01/1200x675-1.jpg",
        },
      ],
      content: [
        {
          acf_fc_layout: "text_left_+_image_right",
          "text_+_image": [
            {
              title: "Welcome to Workers' Directory Nexus (WDN).",
              text:
                "<p>Workers&#8217; Directory Nexus (WDN) provides a simple and effective way to connect Companies(Employers) with Candidates(Employees). Candidates maintain their profile, and Companies find required Candidates for Vacant Job positions via quick and advance search functionalities.</p>\n<h3>Our Objective</h3>\n<p>WDN aims to remove the gap between Companies and Candidates by providing a better way to connect them and reduces the cost and time involved in the overall hiring process.</p>\n",
              button_text: "",
              button_url: "",
              image: {
                ID: 1494,
                id: 1494,
                title: "our-objective",
                filename: "our-objective.webp",
                filesize: 66498,
                url: Placeholder,
                link: "https://wdnexuswp.com/about/attachment/our-objective/",
                alt: "Our Objective",
                author: "1",
                description: "",
                caption: "",
                name: "our-objective",
                status: "inherit",
                uploaded_to: 226,
                date: "2022-01-21 06:02:30",
                modified: "2022-02-10 05:19:22",
                menu_order: 0,
                mime_type: "image/webp",
                type: "image",
                subtype: "webp",
                icon:
                  "https://wdnexuswp.com/wp-includes/images/media/default.png",
                width: 457,
                height: 510,
                sizes: {
                  thumbnail:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/our-objective-150x150.webp",
                  "thumbnail-width": 150,
                  "thumbnail-height": 150,
                  medium:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/our-objective-269x300.webp",
                  "medium-width": 269,
                  "medium-height": 300,
                  medium_large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/our-objective.webp",
                  "medium_large-width": 457,
                  "medium_large-height": 510,
                  large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/our-objective.webp",
                  "large-width": 457,
                  "large-height": 510,
                  "1536x1536":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/our-objective.webp",
                  "1536x1536-width": 457,
                  "1536x1536-height": 510,
                  "2048x2048":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/our-objective.webp",
                  "2048x2048-width": 457,
                  "2048x2048-height": 510,
                  "post-thumbnail":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/our-objective.webp",
                  "post-thumbnail-width": 457,
                  "post-thumbnail-height": 510,
                },
              },
            },
          ],
        },
        {
          acf_fc_layout: "image_left_+_text_right",
          "image_+_text": [
            {
              image: {
                ID: 1496,
                id: 1496,
                title: "how-WDN-works",
                filename: "how-WDN-works.webp",
                filesize: 76032,
                url: Placeholder,
                link: "https://wdnexuswp.com/about/attachment/how-wdn-works/",
                alt: "How WDN works",
                author: "1",
                description: "",
                caption: "",
                name: "how-wdn-works",
                status: "inherit",
                uploaded_to: 226,
                date: "2022-01-21 06:09:16",
                modified: "2022-02-10 05:19:08",
                menu_order: 0,
                mime_type: "image/webp",
                type: "image",
                subtype: "webp",
                icon:
                  "https://wdnexuswp.com/wp-includes/images/media/default.png",
                width: 457,
                height: 510,
                sizes: {
                  thumbnail:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-150x150.webp",
                  "thumbnail-width": 150,
                  "thumbnail-height": 150,
                  medium:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-269x300.webp",
                  "medium-width": 269,
                  "medium-height": 300,
                  medium_large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works.webp",
                  "medium_large-width": 457,
                  "medium_large-height": 510,
                  large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works.webp",
                  "large-width": 457,
                  "large-height": 510,
                  "1536x1536":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works.webp",
                  "1536x1536-width": 457,
                  "1536x1536-height": 510,
                  "2048x2048":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works.webp",
                  "2048x2048-width": 457,
                  "2048x2048-height": 510,
                  "post-thumbnail":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works.webp",
                  "post-thumbnail-width": 457,
                  "post-thumbnail-height": 510,
                },
              },
              title: "How WDN works?",
              text:
                "<p>The process is simple and straightforward. Candidates and Companies are required to register into WDN and need to verify themselves. Companies are then allowed to do searches based on their Company&#8217;s requirements to find the Candidate(s) matching the skills criteria. Candidates are allowed to maintain their profile and monitor Companies who&#8217;ve visited their profile.</p>\n",
              button_text: "",
              button_url: "",
            },
          ],
        },
        {
          acf_fc_layout: "title_+_description",
          alignment: "1",
          title: "Why WDN?",
          description:
            "<p>WDN has a quick and time-saving mechanism eliminating the tedious and expensive hiring processes. Candidate fills in their details and WDN does the rest to make their profile reachable to Companies. Companies only need to pay based on CVs selection, which makes the process cost-effective. As WDN provides a speedy way to search Candidate(s), a Company can save a lot of time involved in the process.</p>\n<p>WDN is for all those Candidates who are in a legal age seeking a career or looking for a better one, and Companies of any size looking for the Right Candidate(s) in their Companies.</p>\n",
        },
        {
          acf_fc_layout: "cta_streak",
          background_color: "#2B337F",
          streak_content:
            '<p>For further queries write us at\u00a0<a href="mailto:hi@wdnexus.com ">hi@wdnexus.com </a></p>\n',
        },
      ],
    },
    featured_image_src: {
      full:
        "https://wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
      large:
        "https://wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
      medium:
        "https://wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
      thumbnail:
        "https://wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
    },
    author_details: {
      id: 1,
      user_nicename: "codepros",
      user_url: "https://wdnexuswp.com/author/codepros/",
      firstname: "",
      lastname: "",
      display_name: "codepros",
    },
    published_on: "Jan 28, 2021",
    _links: {
      self: [
        {
          href: "https://wdnexuswp.com/wp-json/wp/v2/pages/226",
        },
      ],
      collection: [
        {
          href: "https://wdnexuswp.com/wp-json/wp/v2/pages",
        },
      ],
      about: [
        {
          href: "https://wdnexuswp.com/wp-json/wp/v2/types/page",
        },
      ],
      author: [
        {
          embeddable: true,
          href: "https://wdnexuswp.com/wp-json/wp/v2/users/1",
        },
      ],
      replies: [
        {
          embeddable: true,
          href: "https://wdnexuswp.com/wp-json/wp/v2/comments?post=226",
        },
      ],
      "version-history": [
        {
          count: 21,
          href: "https://wdnexuswp.com/wp-json/wp/v2/pages/226/revisions",
        },
      ],
      "predecessor-version": [
        {
          id: 2113,
          href: "https://wdnexuswp.com/wp-json/wp/v2/pages/226/revisions/2113",
        },
      ],
      "wp:attachment": [
        {
          href: "https://wdnexuswp.com/wp-json/wp/v2/media?parent=226",
        },
      ],
      curies: [
        {
          name: "wp",
          href: "https://api.w.org/{rel}",
          templated: true,
        },
      ],
    },
  },
]

export default aboutData
