import React from "react"

import CandidateCard from "../candidate-card/candidate-card"
import formatCandidateCardDataForStatistics from "../../utils/format-candidate-card-data-for-statistics"

const TopCandidates = ({
  stats,
  setProcessing,
  processing,
  cart,
  shortlist,
}) => {
  return (
    <>
      <h3 className="top-candidates mb-4">
        Top Candidates similar to Your Industry
      </h3>
      <div className="top-candidates-section">
        {stats.similar_candidates && stats.similar_candidates.length > 0 ? (
          stats.similar_candidates.map(candidate => (
            <CandidateCard
              setLoading={setProcessing}
              loading={processing}
              key={candidate.id}
              candidate={formatCandidateCardDataForStatistics(candidate)}
              cart={cart}
              shortlist={shortlist}
              displayShortlistButton={true}
              displayCartButton={true}
            />
          ))
        ) : (
          <p>Nothing to show</p>
        )}
      </div>
    </>
  )
}

export default TopCandidates
