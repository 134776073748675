import React from "react"
import ReactSelect from "react-select"
import CalanderIcon from "../../images/calendar.svg"

import "./newJobCreatingForm.scss"
import Datepicker from "../input/datepicker"

export default function CreateNewJobForm() {
  const [rangeValue, setRangeValue] = React.useState(20)
  //   const [inputTypeDate, setInputTypeDate] = React.useState(false)

  return (
    <div className="wdn-new wdn-job-posting-form-wrapper py-52px">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="wjp-form">
              <div className="wjpf-title">
                <h5>Post a New Job</h5>
              </div>
              <div className="wjpf-input-fields">
                <div className="input-field">
                  <label htmlFor="post">Job Title *</label>
                  <input
                    type="text"
                    name="post"
                    placeholder="Eg : Developer "
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="jobDescription">Job Description *</label>
                  <textarea name="jobDescription" id="" rows="6"></textarea>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="input-field">
                      <label>No. of Candidates *</label>
                      <input type="text" name="post" />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-field">
                      <label>Address</label>
                      <input
                        type="text"
                        name="post"
                        placeholder="Eg : Kathmandu "
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-field">
                      <label>Gender *</label>
                      <div className="bordered-select-field">
                        <ReactSelect
                          className="select-element"
                          isSearchable
                          options={[
                            { value: "Male", label: "Male" },
                            { value: "Female", label: "Female" },
                            { value: "Other", label: "Other" },
                          ]}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-field">
                      <label>Job Location *</label>
                      <div className="bordered-select-field">
                        <ReactSelect
                          className="select-element"
                          isSearchable
                          placeholder="Eg : Kathmandu"
                          options={[
                            { value: "Kathmandu", label: "Kathmandu" },
                            { value: "Butwal", label: "Butwal" },
                            { value: "Pokhara", label: "Pokhara" },
                          ]}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="input-field">
                      <label>Job Industry *</label>
                      <div className="bordered-select-field">
                        <ReactSelect
                          className="select-element"
                          isSearchable
                          options={[
                            { value: "Kathmandu", label: "Kathmandu" },
                            { value: "Butwal", label: "Butwal" },
                            { value: "Pokhara", label: "Pokhara" },
                          ]}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-field">
                      <label>Education *</label>
                      <div className="bordered-select-field">
                        <ReactSelect
                          className="select-element"
                          isSearchable
                          options={[
                            { value: "Kathmandu", label: "Kathmandu" },
                            { value: "Butwal", label: "Butwal" },
                            { value: "Pokhara", label: "Pokhara" },
                          ]}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-field">
                      <label>Work Type Preferred *</label>
                      <div className="bordered-select-field">
                        <ReactSelect
                          className="select-element"
                          isSearchable
                          options={[
                            { value: "Kathmandu", label: "Kathmandu" },
                            { value: "Butwal", label: "Butwal" },
                            { value: "Pokhara", label: "Pokhara" },
                          ]}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-field">
                      <label>Work Shift Preferred *</label>
                      <div className="bordered-select-field">
                        <ReactSelect
                          className="select-element"
                          isSearchable
                          options={[
                            { value: "Kathmandu", label: "Kathmandu" },
                            { value: "Butwal", label: "Butwal" },
                            { value: "Pokhara", label: "Pokhara" },
                          ]}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-field">
                      <label>Skills *</label>
                      <div className="bordered-select-field">
                        <ReactSelect
                          className="select-element"
                          isSearchable
                          options={[
                            { value: "Kathmandu", label: "Kathmandu" },
                            { value: "Butwal", label: "Butwal" },
                            { value: "Pokhara", label: "Pokhara" },
                          ]}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-field">
                      <label>Expected Salary *</label>

                      <div className="row">
                        <div className="col-6">
                          <input type="number" placeholder="Min" min={0} />
                        </div>
                        <div className="col-6">
                          <input type="number" placeholder="Max" min={0} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-field">
                      <label>End Date *</label>
                      <div className="date-field">
                        <Datepicker placeholderText="MM - DD - YYYY" />
                        <div className="date-icon">
                          <img
                            src={CalanderIcon}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input-field">
                      <label>Experience *</label>

                      <div className="range-control">
                        <input
                          type="range"
                          className="min-to-max"
                          value={rangeValue}
                          min={0}
                          max={100}
                          onChange={e =>
                            setRangeValue(parseInt(e.target.value))
                          }
                        />
                        <div
                          className="filler"
                          style={{ width: `${rangeValue}%` }} // Set the width inline
                        ></div>
                        <div className="range-controllers">
                          <button className="min-max"></button>

                          <span>0 Yrs</span>
                          <span
                            style={{
                              left: `${rangeValue}%`,
                              transform: `translateX(-${rangeValue}%)`,
                            }}
                          >
                            {rangeValue} Yrs
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <a href="#" className="btn btn-filled-v4 mt-20px mt-lg-0">
                  Post Job
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
