import React, { useEffect, useState } from "react"

import { COMPANY_SIDEBAR_MENU_STEP } from "../../../constants/sidebar-menu"
import { CART_MENU } from "../../../constants/profile-menu"

import CompanyProfileLayout from "../../../components/layout/company-profile"
import CompanyCart from "../../../components/company-profile/cart/cart"

const CartPage = ({ location }) => {
  const [processing, setProcessing] = useState(false)

  useEffect(() => {}, [processing])

  return (
    <CompanyProfileLayout
      menu={CART_MENU}
      sidebarMenu={COMPANY_SIDEBAR_MENU_STEP}
      activeSidebar="Cart"
      active="Cart"
      title="Cart"
      location={location}
    >
      <CompanyCart processing={processing} setProcessing={setProcessing} />
    </CompanyProfileLayout>
  )
}

export default CartPage
