import React, { useState, useContext, useEffect } from "react"

import { COMPANY_SIDEBAR_MENU_STEP } from "../../../constants/sidebar-menu"
import { COMPANY_STATISTICS_MENU } from "../../../constants/profile-menu"

import { GlobalAuthContext } from "../../../context/authContext"

import CompanyProfileLayout from "../../../components/layout/company-profile"
import CompanyStatistics from "../../../components/company-profile/statistics/company-statistics"

const CompanyStatisticsPage = ({ location }) => {
  const [stats, setStats] = useState({})
  const [processing, setProcessing] = useState(false)

  const { state } = useContext(GlobalAuthContext)

  useEffect(() => {}, [state?.isAuthenticated, state?.isEmailVerified]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CompanyProfileLayout
      menu={COMPANY_STATISTICS_MENU}
      sidebarMenu={COMPANY_SIDEBAR_MENU_STEP}
      activeSidebar="Dashboard"
      active="Dashboard"
      title="Dashboard"
      location={location}
      showSearchButton
    >
      <CompanyStatistics
        processing={processing}
        setProcessing={setProcessing}
        stats={stats}
        cart={state?.cart}
        shortlist={state?.shortlist}
        setStats={setStats}
      />
    </CompanyProfileLayout>
  )
}

export default CompanyStatisticsPage
