import React from "react"
import { Link } from "gatsby"
import { formatDate } from "../../../../utils/format-date-time"

const JobStatistics = ({ job_data }) => {
  return (
    <div className="job-stat py-4 px-3 mb-lg-0 mb-4">
      <div className="title mb-4">
        <h4>Jobs Statistics</h4>
      </div>
      <div className="content mb-4">
        {
          <p className="mb-2 fw-normal">
            Views:{" "}
            <span className="black fw-normal">{job_data?.views ?? "0"}</span>
          </p>
        }
        <p className="mb-2 fw-normal">
          Applicants:{" "}
          <span className="black fw-normal">{job_data?.applicants ?? "0"}</span>
        </p>
        <p className="mb-2 fw-normal">
          Shortlisted:{" "}
          <span className="black fw-normal">
            {job_data?.shortlisted ?? "0"}
          </span>
        </p>
        <p className="mb-2 fw-normal">
          Posted On:{" "}
          <span className="black fw-normal">
            {formatDate(job_data?.job_created)}
          </span>
        </p>
        <p className="mb-2 fw-normal">
          Ends On:{" "}
          <span className="black fw-normal">
            {formatDate(job_data?.end_date)}
          </span>
        </p>
      </div>
      <Link
        to={"/company/detail/" + job_data?.company?.username}
        className="btn p-3 my-2 w-100 btn-custom rounded primary"
      >
        View Company Profile
      </Link>
    </div>
  )
}

export default JobStatistics
