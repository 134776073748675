// import axios from "axios"
import axios from "../../../constants/axios"

import { navigate } from "gatsby"

import endpoints from "../../../constants/endpoints"

export const GET_CANDIDATE_NOTIFICATIONS = async (
  setNotifications,
  setLoading,
  currentPage
) => {
  try {
    const response = await axios.get(
      process.env.GATSBY_BASE_URL +
        endpoints.CANDIDATE_NOTIFICATION_ROUTE +
        `?page=${currentPage + 1}`
    )
    const { data } = response
    setNotifications(data || { results: [] })
    setLoading(false)
  } catch (err) {
    setLoading(false)
    if (err.response && err.response.status === 404) {
      navigate("/404")
    }
  }
}

export const NOTIFICATION_READ = async (id, setSeen, state, dispatch) => {
  try {
    const response = await axios.put(
      process.env.GATSBY_BASE_URL +
        endpoints.CANDIDATE_NOTIFICATION_ROUTE +
        id +
        "/"
    )
    if (response.status === 201) {
      setSeen(true)
    }
    dispatch({
      type: "CANDIDATE_COUNTS",
      payload: {
        ...state.candidate_counts,
        notification: state.candidate_counts.notification - 1,
      },
    })
  } catch (error) {}
}
