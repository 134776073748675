import React, { useContext, useState, useEffect } from "react"

import { navigate } from "gatsby"
import { Row, Col } from "react-bootstrap"

import candidateCardDataFormat from "../../../utils/candidate-card-data-format"

import { CART_FETCH } from "../statistics/action/statisticsAction"
import { GlobalAuthContext } from "../../../context/authContext"

import "./cart.scss"
import CandidateCard from "../../candidate-card/candidate-card"

const CompanyCart = ({ processing, setProcessing }) => {
  const [cart, setCart] = useState([])
  const [refresh, setRefresh] = useState(false)
  let subtotal = 0
  const calculateSubtotal = e => {
    subtotal = subtotal + e
  }

  const checkoutClick = () => {
    navigate("/company/checkout")
  }

  const { state, dispatch } = useContext(GlobalAuthContext)

  useEffect(() => {
    if (state?.isAuthenticated && state?.user?.role === 3) {
      CART_FETCH(dispatch, setCart)
    }
  }, [refresh]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="company-statistics mt-5 mb-5">
      <div className="top-candidates-section mb-4">
        {state?.cart && state?.cart.length > 0 ? (
          state?.cart.map(candidate => {
            calculateSubtotal(candidate.candidate.price)
            return (
              <CandidateCard
                loading={processing}
                setLoading={setProcessing}
                key={candidate.id}
                candidate={candidateCardDataFormat(candidate)}
                cart={cart}
                page="cart"
                displayCartDeleteButton={true}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            )
          })
        ) : (
          <p>Cart is Empty.</p>
        )}
        {cart && cart.length > 0 && (
          <Row className="align-items-center mt-4">
            <Col xs="12" sm="3" className="offset-sm-9 mb-3">
              Subtotal:{" "}
              <strong>
                {cart[0].candidate.currency}:{subtotal}
              </strong>
            </Col>
            <Col xs="12" sm="3" className="offset-sm-9">
              <button
                onClick={checkoutClick}
                className="btn primary no-outline w-100 checkout mt-3 mt-sm-0"
              >
                Checkout
              </button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}

export default CompanyCart
