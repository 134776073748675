import React, { useState, useContext, useEffect } from "react"

import { COMPANY_SIDEBAR_MENU_STEP } from "../../../constants/sidebar-menu"
import { SHORTLIST_MENU } from "../../../constants/profile-menu"

import { GlobalAuthContext } from "../../../context/authContext"

import CompanyProfileLayout from "../../../components/layout/company-profile"
import Shortlist from "../../../components/company-profile/shortlist/shortlist"

const ShortlistPage = ({ location }) => {
  const [processing, setProcessing] = useState(false)

  const { state } = useContext(GlobalAuthContext)

  useEffect(() => {}, [processing, state?.user]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CompanyProfileLayout
      menu={SHORTLIST_MENU}
      sidebarMenu={COMPANY_SIDEBAR_MENU_STEP}
      activeSidebar="Shortlist"
      active="Shortlist"
      title="Shortlist"
      location={location}
    >
      <Shortlist processing={processing} setProcessing={setProcessing} />
    </CompanyProfileLayout>
  )
}

export default ShortlistPage
