import React, { useContext, useEffect, useState } from "react"

import candidateCardDataFormat from "../../../utils/candidate-card-data-format"

import { GlobalAuthContext } from "../../../context/authContext"

import "./shortlist.scss"
import CandidateCard from "../../candidate-card/candidate-card"
import {
  CART_FETCH,
  SHORTLIST_FETCH,
} from "../statistics/action/statisticsAction"

const CompanyShortlist = ({ processing, setProcessing }) => {
  const [shortlist, setShortlist] = useState([])
  const [cart, setCart] = useState([])
  const [refresh, setRefresh] = useState(false)

  const { state, dispatch } = useContext(GlobalAuthContext)

  useEffect(() => {
    if (state.isAuthenticated && state.user.role === 3) {
      const user = state?.user
      CART_FETCH(dispatch, setCart)
      SHORTLIST_FETCH(dispatch, setShortlist, user.company.id)
    }
  }, [refresh]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="company-statistics mt-5 mb-5">
      <h3 className="top-candidates mb-4">Candidates You have shortlisted</h3>
      <div className="top-candidates-section">
        {state?.shortlist && state?.shortlist.length > 0 ? (
          state?.shortlist.map((candidate, index) => (
            <CandidateCard
              loading={processing}
              setLoading={setProcessing}
              key={index}
              candidate={candidateCardDataFormat(candidate)}
              cart={cart}
              shortlist={shortlist}
              displayShortlistDeleteButton={true}
              displayCartButton={true}
              page="shortlist"
              refresh={refresh}
              setRefresh={setRefresh}
              isFromJobVisible
            />
          ))
        ) : (
          <p>You haven't shortlisted anyone yet.</p>
        )}
      </div>
    </div>
  )
}

export default CompanyShortlist
