import React, { useState, useEffect, useContext } from "react"

import { SIDEBAR_MENU_STEP } from "../../../constants/sidebar-menu"
import { CANDIDATE_NOTIFICATION_MENU } from "../../../constants/profile-menu"

import { GlobalAuthContext } from "../../../context/authContext"

import { GET_CANDIDATE_NOTIFICATIONS } from "../../../components/candidate-profile/notification/notifications-actions"

import Spinner from "../../../components/spinner/spinner"
import CandidateProfileLayout from "../../../components/layout/candidate-profile"
import Notifications from "../../../components/notification/notification"

const NotificationsPage = ({ location }) => {
  const [notifications, setNotifications] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)

  const { state, dispatch } = useContext(GlobalAuthContext)

  useEffect(() => {
    if (state && state.user && state.user.role === 2 && state.hasProfile) {
      GET_CANDIDATE_NOTIFICATIONS(
        setNotifications,
        setLoading,
        currentPage,
        dispatch
      )
    }
  }, [currentPage, state.isAuthenticated, state.hasProfile]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <CandidateProfileLayout
      menu={CANDIDATE_NOTIFICATION_MENU}
      sidebarMenu={SIDEBAR_MENU_STEP}
      activeSidebar="Notifications"
      active="Notifications"
      title="Notifications"
      location={location}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Notifications
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          notifications={notifications}
        />
      )}
    </CandidateProfileLayout>
  )
}
export default NotificationsPage
