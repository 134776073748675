import React, { useContext, useEffect, useState } from "react"

import { Row } from "react-bootstrap"

import { STATISTICS_FETCH } from "./action/statisticsAction"

import VIEWS from "../../../images/icons/wdn-view.png"
import SHORTLIST from "../../../images/icons/wdn-shortlist.png"

import "./company-statistics.scss"
import Spinner from "../../spinner/spinner"
import ViewsShortlist from "../../view-shortlist/views-shortlist"
import TopCandidates from "../../top-candidates"
import { GlobalAuthContext } from "../../../context/authContext"

const CompanyStatistics = ({
  stats,
  setStats,
  processing,
  setProcessing,
  cart,
  shortlist,
}) => {
  const [isLoading, setLoading] = useState(false)
  const { dispatch } = useContext(GlobalAuthContext)

  useEffect(() => {
    setLoading(true)
    try {
      const user = JSON.parse(localStorage.getItem("user"))
      const username = user.username
      STATISTICS_FETCH(setStats, setLoading, username, dispatch)
    } catch (error) {}
  }, [processing]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="company-statistics mt-5 mb-5">
      <div className="views-section-wrapper mb-5">
        <Row>
          {isLoading ? (
            <Spinner />
          ) : (
            <ViewsShortlist
              stat={stats.shortlist}
              title="Shortlisted"
              isLoading={processing}
              icon={VIEWS}
            />
          )}
          {isLoading ? (
            <Spinner />
          ) : (
            <ViewsShortlist
              stat={stats.paidcv}
              title="Paid CVs"
              isLoading={processing}
              icon={SHORTLIST}
            />
          )}
        </Row>
      </div>
      <TopCandidates
        stats={stats}
        processing={processing}
        setProcessing={setProcessing}
        cart={cart}
        shortlist={shortlist}
      />
    </div>
  )
}

export default CompanyStatistics
