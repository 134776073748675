import Placeholder from "../../images/placeholder.jpg"

const forCandidates = [
  {
    id: 220,
    date: "2021-01-28T08:53:44",
    date_gmt: "2021-01-28T08:53:44",
    guid: {
      rendered: "https://dev.wdnexuswp.com/?page_id=220",
    },
    modified: "2022-07-22T10:15:57",
    modified_gmt: "2022-07-22T10:15:57",
    slug: "for-candidates",
    status: "publish",
    type: "page",
    link: "https://wdnexuswp.com/for-candidates/",
    title: {
      rendered: "For Candidates",
    },
    content: {
      rendered: "",
      protected: false,
    },
    excerpt: {
      rendered: "",
      protected: false,
    },
    author: 1,
    featured_media: 0,
    parent: 0,
    menu_order: 0,
    comment_status: "closed",
    ping_status: "closed",
    template: "",
    meta: [],
    acf: {
      seo_metadata: [
        {
          title: "For Candidates - WDN",
          meta_keywords:
            "For Candidates - WDN, Connects Candidates With Companies, Workers\u2019 Directory Nexus, Find Companies For Job, Get Your Dream Job",
          description:
            "Workers' Directory Nexus provides a simple and effective way to connect candidates with companies.",
          meta_image:
            "https://wdnexuswp.com/wp-content/uploads/2022/01/1200x627-3.jpg",
          facebook_title: "For Candidates - WDN",
          facebook_description:
            "Workers' Directory Nexus provides a simple and effective way to connect candidates with companies. ",
          facebook_image:
            "https://wdnexuswp.com/wp-content/uploads/2022/01/1200x630-3.jpg",
          twitter_title: "For Candidates - WDN",
          twitter_description:
            "Workers' Directory Nexus provides a simple and effective way to connect candidates with companies. ",
          twitter_image:
            "https://wdnexuswp.com/wp-content/uploads/2022/01/1200x675-3.jpg",
        },
      ],
      content: [
        {
          acf_fc_layout: "banner",
          background_color: "#EDF7FC",
          title:
            'Build Career <span style="color:#2b3480">Without Applying</span> For Jobs <style>.hero-section .container .row .hero__input-box select{border: 1px solid grey !important} @media (max-width: 767px){.hero-section{padding: 2rem 0;} .hero-section .container .row .order-lg-last img{width: 75%;}}  .hero-section .container .row h1{font-size: 40px!important;} @media (min-width: 1200px){ .hero-section .container .row h1{font-size: 58px!important}}</style><br/><a class=" btn rounded no-border btn-custom primary py-2 mt-4" href="/signup"><span>Signup Today!</span></a>',
          include_search_bar: "0",
          image: {
            ID: 1998,
            id: 1998,
            title: "for-candidates-banner",
            filename: "for-candidates-banner.png",
            filesize: 163666,
            url: Placeholder,
            link:
              "https://wdnexuswp.com/for-candidates/attachment/for-candidates-banner/",
            alt: "",
            author: "1",
            description: "",
            caption: "",
            name: "for-candidates-banner",
            status: "inherit",
            uploaded_to: 220,
            date: "2022-07-22 10:15:41",
            modified: "2022-07-22 10:15:41",
            menu_order: 0,
            mime_type: "image/png",
            type: "image",
            subtype: "png",
            icon: "https://wdnexuswp.com/wp-includes/images/media/default.png",
            width: 392,
            height: 329,
            sizes: {
              thumbnail:
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-candidates-banner-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              medium:
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-candidates-banner-300x252.png",
              "medium-width": 300,
              "medium-height": 252,
              medium_large:
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-candidates-banner.png",
              "medium_large-width": 392,
              "medium_large-height": 329,
              large:
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-candidates-banner.png",
              "large-width": 392,
              "large-height": 329,
              "1536x1536":
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-candidates-banner.png",
              "1536x1536-width": 392,
              "1536x1536-height": 329,
              "2048x2048":
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-candidates-banner.png",
              "2048x2048-width": 392,
              "2048x2048-height": 329,
              "post-thumbnail":
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-candidates-banner.png",
              "post-thumbnail-width": 392,
              "post-thumbnail-height": 329,
            },
          },
          cta_url: "",
          cta_text: "",
        },
        {
          acf_fc_layout: "three_column_content",
          title: "",
          description:
            "<style>.hero-section + .three-column-content h3{margin-bottom: 20px !important;}</style>\n<style> .job-step-counter{width: 40px; height: 40px; background-color: #4fb5df; border-radius: 50%; position: relative; display: inline-block; top: 10px; margin-right: 10px;} .job-step-counter span{position: absolute; font-size: 30px; color: #fff; top: -3px; left: 0; right: 0; bottom: 0;} </style>\n<style>.hero-section + .three-column-content .icon-container,.hero-section + .three-column-content .col-12.col{display: none;}</style>\n",
          column_content: [
            {
              column_imageicon: false,
              column_title:
                '<span class="job-step-counter"><span>1</span></span>Easy Signup',
              column_description: "<p>Quickly Register with your email.</p>\n",
            },
            {
              column_imageicon: false,
              column_title:
                '<span class="job-step-counter"><span>2</span></span>Complete Profile',
              column_description: "<p>Your profile is your strength.</p>\n",
            },
            {
              column_imageicon: false,
              column_title:
                '<span class="job-step-counter"><span>3</span></span>Get Discovered',
              column_description:
                "<p>Companies will come looking for you.</p>\n",
            },
          ],
        },
        {
          acf_fc_layout: "text_left_+_image_right",
          "text_+_image": [
            {
              title: "How WDN works for Candidates?",
              text:
                "<p>Quickly register into WDN, and fill in all the necessary details. Add detailed information about your education, experiences, and your expectations.</p>\n",
              button_text: "Get Started Now!",
              button_url: "/signup/",
              image: {
                ID: 1504,
                id: 1504,
                title: "how-WDN-works-for-candidates",
                filename: "how-WDN-works-for-candidates.webp",
                filesize: 99746,
                url: Placeholder,
                link:
                  "https://wdnexuswp.com/for-candidates/attachment/how-wdn-works-for-candidates/",
                alt: "How WDN works for Candidates",
                author: "1",
                description: "",
                caption: "",
                name: "how-wdn-works-for-candidates",
                status: "inherit",
                uploaded_to: 220,
                date: "2022-01-21 06:19:58",
                modified: "2022-02-10 05:17:39",
                menu_order: 0,
                mime_type: "image/webp",
                type: "image",
                subtype: "webp",
                icon:
                  "https://wdnexuswp.com/wp-includes/images/media/default.png",
                width: 555,
                height: 410,
                sizes: {
                  thumbnail:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-candidates-150x150.webp",
                  "thumbnail-width": 150,
                  "thumbnail-height": 150,
                  medium:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-candidates-300x222.webp",
                  "medium-width": 300,
                  "medium-height": 222,
                  medium_large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-candidates.webp",
                  "medium_large-width": 555,
                  "medium_large-height": 410,
                  large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-candidates.webp",
                  "large-width": 555,
                  "large-height": 410,
                  "1536x1536":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-candidates.webp",
                  "1536x1536-width": 555,
                  "1536x1536-height": 410,
                  "2048x2048":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-candidates.webp",
                  "2048x2048-width": 555,
                  "2048x2048-height": 410,
                  "post-thumbnail":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-candidates.webp",
                  "post-thumbnail-width": 555,
                  "post-thumbnail-height": 410,
                },
              },
            },
          ],
        },
        {
          acf_fc_layout: "image_left_+_text_right",
          "image_+_text": [
            {
              image: {
                ID: 1505,
                id: 1505,
                title: "create-an-account-and-get-started",
                filename: "create-an-account-and-get-started.webp",
                filesize: 11632,
                url: Placeholder,
                link:
                  "https://wdnexuswp.com/for-candidates/attachment/create-an-account-and-get-started/",
                alt: "Create an Account and Get Started",
                author: "1",
                description: "",
                caption: "",
                name: "create-an-account-and-get-started",
                status: "inherit",
                uploaded_to: 220,
                date: "2022-01-21 06:20:24",
                modified: "2022-02-10 05:17:31",
                menu_order: 0,
                mime_type: "image/webp",
                type: "image",
                subtype: "webp",
                icon:
                  "https://wdnexuswp.com/wp-includes/images/media/default.png",
                width: 636,
                height: 424,
                sizes: {
                  thumbnail:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/create-an-account-and-get-started-150x150.webp",
                  "thumbnail-width": 150,
                  "thumbnail-height": 150,
                  medium:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/create-an-account-and-get-started-300x200.webp",
                  "medium-width": 300,
                  "medium-height": 200,
                  medium_large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/create-an-account-and-get-started.webp",
                  "medium_large-width": 636,
                  "medium_large-height": 424,
                  large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/create-an-account-and-get-started.webp",
                  "large-width": 636,
                  "large-height": 424,
                  "1536x1536":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/create-an-account-and-get-started.webp",
                  "1536x1536-width": 636,
                  "1536x1536-height": 424,
                  "2048x2048":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/create-an-account-and-get-started.webp",
                  "2048x2048-width": 636,
                  "2048x2048-height": 424,
                  "post-thumbnail":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/create-an-account-and-get-started.webp",
                  "post-thumbnail-width": 636,
                  "post-thumbnail-height": 424,
                },
              },
              title: " Get the Right Exposure.",
              text:
                "<p>WDN allows you to find the right exposure and bring your profile to the Companies reach.</p>\n",
              button_text: "Create Free Account",
              button_url: "/signup/",
            },
          ],
        },
        {
          acf_fc_layout: "text_left_+_image_right",
          "text_+_image": [
            {
              title: "Track your Progress",
              text:
                "<p>Keep track of Companies that interact with your profile.</p>\n",
              button_text: "Get Started Now!",
              button_url: "/signup/",
              image: {
                ID: 1506,
                id: 1506,
                title: "track-your-progress",
                filename: "track-your-progress.webp",
                filesize: 79772,
                url: Placeholder,
                link:
                  "https://wdnexuswp.com/for-candidates/attachment/track-your-progress/",
                alt: "Track your Progress",
                author: "1",
                description: "",
                caption: "",
                name: "track-your-progress",
                status: "inherit",
                uploaded_to: 220,
                date: "2022-01-21 06:21:00",
                modified: "2022-02-10 05:17:23",
                menu_order: 0,
                mime_type: "image/webp",
                type: "image",
                subtype: "webp",
                icon:
                  "https://wdnexuswp.com/wp-includes/images/media/default.png",
                width: 553,
                height: 355,
                sizes: {
                  thumbnail:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/track-your-progress-150x150.webp",
                  "thumbnail-width": 150,
                  "thumbnail-height": 150,
                  medium:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/track-your-progress-300x193.webp",
                  "medium-width": 300,
                  "medium-height": 193,
                  medium_large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/track-your-progress.webp",
                  "medium_large-width": 553,
                  "medium_large-height": 355,
                  large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/track-your-progress.webp",
                  "large-width": 553,
                  "large-height": 355,
                  "1536x1536":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/track-your-progress.webp",
                  "1536x1536-width": 553,
                  "1536x1536-height": 355,
                  "2048x2048":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/track-your-progress.webp",
                  "2048x2048-width": 553,
                  "2048x2048-height": 355,
                  "post-thumbnail":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/track-your-progress.webp",
                  "post-thumbnail-width": 553,
                  "post-thumbnail-height": 355,
                },
              },
            },
          ],
        },
        {
          acf_fc_layout: "image_left_+_text_right",
          "image_+_text": [
            {
              image: {
                ID: 1507,
                id: 1507,
                title: "get-your-dream-job-with-us",
                filename: "get-your-dream-job-with-us.webp",
                filesize: 83360,
                url: Placeholder,
                link:
                  "https://wdnexuswp.com/for-candidates/attachment/get-your-dream-job-with-us/",
                alt: "Get your dream job with us",
                author: "1",
                description: "",
                caption: "",
                name: "get-your-dream-job-with-us",
                status: "inherit",
                uploaded_to: 220,
                date: "2022-01-21 06:21:22",
                modified: "2022-02-10 05:15:07",
                menu_order: 0,
                mime_type: "image/webp",
                type: "image",
                subtype: "webp",
                icon:
                  "https://wdnexuswp.com/wp-includes/images/media/default.png",
                width: 555,
                height: 360,
                sizes: {
                  thumbnail:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/get-your-dream-job-with-us-150x150.webp",
                  "thumbnail-width": 150,
                  "thumbnail-height": 150,
                  medium:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/get-your-dream-job-with-us-300x195.webp",
                  "medium-width": 300,
                  "medium-height": 195,
                  medium_large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/get-your-dream-job-with-us.webp",
                  "medium_large-width": 555,
                  "medium_large-height": 360,
                  large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/get-your-dream-job-with-us.webp",
                  "large-width": 555,
                  "large-height": 360,
                  "1536x1536":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/get-your-dream-job-with-us.webp",
                  "1536x1536-width": 555,
                  "1536x1536-height": 360,
                  "2048x2048":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/get-your-dream-job-with-us.webp",
                  "2048x2048-width": 555,
                  "2048x2048-height": 360,
                  "post-thumbnail":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/get-your-dream-job-with-us.webp",
                  "post-thumbnail-width": 555,
                  "post-thumbnail-height": 360,
                },
              },
              title: " Get your dream job with WDN.",
              text:
                "<p>We help the right Companies to approach you based on your education, skills, past experiences and your other profile attributes.</p>\n",
              button_text: "Create Free Account",
              button_url: "/signup/",
            },
          ],
        },
        {
          acf_fc_layout: "title_+_description",
          alignment: "1",
          title: "Companies Using Our Platform",
          description:
            "<p>More and More Companies are using our platform to connect with candidates instantly.</p>\n",
        },
        {
          acf_fc_layout: "single_full_imagevideo",
          choose: "0",
          image: {
            ID: 1862,
            id: 1862,
            title: "logos-banner",
            filename: "logos-banner.jpg",
            filesize: 202054,
            url: Placeholder,
            link: "https://wdnexuswp.com/?attachment_id=1862",
            alt: "Companies we've helped - WDN",
            author: "1",
            description: "",
            caption: "",
            name: "logos-banner",
            status: "inherit",
            uploaded_to: 216,
            date: "2022-07-18 09:08:56",
            modified: "2022-07-18 09:09:09",
            menu_order: 0,
            mime_type: "image/jpeg",
            type: "image",
            subtype: "jpeg",
            icon: "https://wdnexuswp.com/wp-includes/images/media/default.png",
            width: 1200,
            height: 600,
            sizes: {
              thumbnail:
                "https://wdnexuswp.com/wp-content/uploads/2022/07/logos-banner-150x150.jpg",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              medium:
                "https://wdnexuswp.com/wp-content/uploads/2022/07/logos-banner-300x150.jpg",
              "medium-width": 300,
              "medium-height": 150,
              medium_large:
                "https://wdnexuswp.com/wp-content/uploads/2022/07/logos-banner-768x384.jpg",
              "medium_large-width": 750,
              "medium_large-height": 375,
              large:
                "https://wdnexuswp.com/wp-content/uploads/2022/07/logos-banner-1024x512.jpg",
              "large-width": 750,
              "large-height": 375,
              "1536x1536":
                "https://wdnexuswp.com/wp-content/uploads/2022/07/logos-banner.jpg",
              "1536x1536-width": 1200,
              "1536x1536-height": 600,
              "2048x2048":
                "https://wdnexuswp.com/wp-content/uploads/2022/07/logos-banner.jpg",
              "2048x2048-width": 1200,
              "2048x2048-height": 600,
              "post-thumbnail":
                "https://wdnexuswp.com/wp-content/uploads/2022/07/logos-banner.jpg",
              "post-thumbnail-width": 1200,
              "post-thumbnail-height": 600,
            },
          },
          video: "",
        },
        {
          acf_fc_layout: "title_+_description",
          alignment: "0",
          title:
            'Any Questions? Checkout our <a href="/faqs" style="color: #4fb5df;">FAQS</a> for Common Q&A\'s and feel free to use our chat support 24X7 for any queries.',
          description: "",
        },
      ],
    },
    featured_image_src: {
      full:
        "https://wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
      large:
        "https://wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
      medium:
        "https://wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
      thumbnail:
        "https://wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
    },
    author_details: {
      id: 1,
      user_nicename: "codepros",
      user_url: "https://wdnexuswp.com/author/codepros/",
      firstname: "",
      lastname: "",
      display_name: "codepros",
    },
    published_on: "Jan 28, 2021",
    _links: {
      self: [
        {
          href: "https://wdnexuswp.com/wp-json/wp/v2/pages/220",
        },
      ],
      collection: [
        {
          href: "https://wdnexuswp.com/wp-json/wp/v2/pages",
        },
      ],
      about: [
        {
          href: "https://wdnexuswp.com/wp-json/wp/v2/types/page",
        },
      ],
      author: [
        {
          embeddable: true,
          href: "https://wdnexuswp.com/wp-json/wp/v2/users/1",
        },
      ],
      replies: [
        {
          embeddable: true,
          href: "https://wdnexuswp.com/wp-json/wp/v2/comments?post=220",
        },
      ],
      "version-history": [
        {
          count: 38,
          href: "https://wdnexuswp.com/wp-json/wp/v2/pages/220/revisions",
        },
      ],
      "predecessor-version": [
        {
          id: 1999,
          href: "https://wdnexuswp.com/wp-json/wp/v2/pages/220/revisions/1999",
        },
      ],
      "wp:attachment": [
        {
          href: "https://wdnexuswp.com/wp-json/wp/v2/media?parent=220",
        },
      ],
      curies: [
        {
          name: "wp",
          href: "https://api.w.org/{rel}",
          templated: true,
        },
      ],
    },
  },
]

export default forCandidates
