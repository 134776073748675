const formatCandidateCardDataForStatistics = (data) => {
    return {
        ...data,
        candidate: {
            id: data.id,
            thumbnail: data.avatar ? (process.env.GATSBY_BASE_URL + data.avatar) : null,
            current_address: data.current_address,
        },
        profile: {
            experience: data.experience,
            expected_salary: data.expected_salary,
            status: data.status,
            position: data.position,
            price: data.price,
            currency: data.currency
        }
    }
}

export default formatCandidateCardDataForStatistics