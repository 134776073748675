import { useState, useEffect } from "react"

const ClientOnly = ({ children }) => {
  const [hasMounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }
  return children
}

export default ClientOnly
