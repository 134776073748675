import Placeholder from "../../images/placeholder.jpg"

const forCompaniesPage = [
  {
    id: 218,
    date: "2021-01-28T08:53:35",
    date_gmt: "2021-01-28T08:53:35",
    guid: {
      rendered: "https://dev.wdnexuswp.com/?page_id=218",
    },
    modified: "2023-06-16T06:01:27",
    modified_gmt: "2023-06-16T06:01:27",
    slug: "for-companies",
    status: "publish",
    type: "page",
    link: "https://wdnexuswp.com/for-companies/",
    title: {
      rendered: "For Companies",
    },
    content: {
      rendered: "",
      protected: false,
    },
    excerpt: {
      rendered: "",
      protected: false,
    },
    author: 1,
    featured_media: 0,
    parent: 0,
    menu_order: 0,
    comment_status: "closed",
    ping_status: "closed",
    template: "",
    meta: [],
    acf: {
      seo_metadata: [
        {
          title: "For Companies - WDN",
          meta_keywords:
            "Workers\u2019 Directory Nexus, WDN For Companies, Simple and Effective, Connects Companies With Candidates, Find Candidates for Companies",
          description:
            "Workers' Directory Nexus provides a simple and effective way to connect companies with candidates. ",
          meta_image:
            "https://wdnexuswp.com/wp-content/uploads/2022/01/1200x627-2.jpg",
          facebook_title: "For Companies - WDN",
          facebook_description:
            "Workers' Directory Nexus provides a simple and effective way to connect companies with candidates.",
          facebook_image:
            "https://wdnexuswp.com/wp-content/uploads/2022/01/1200x630-2.jpg",
          twitter_title: "For Companies - WDN",
          twitter_description:
            "Workers' Directory Nexus provides a simple and effective way to connect companies with candidates. \t",
          twitter_image:
            "https://wdnexuswp.com/wp-content/uploads/2022/01/1200x675-2.jpg",
        },
      ],
      content: [
        {
          acf_fc_layout: "banner",
          background_color: "#EDF7FC",
          title:
            'Find Candidates <span style="color:#2b3480">Without Posting</span> Jobs <style>.hero-section .container .row .hero__input-box select{border: 1px solid grey !important} @media (max-width: 767px){.hero-section{padding: 2rem 0;} .hero-section .container .row .order-lg-last img{width: 75%;} }  .hero-section .container .row h1{font-size: 40px!important;} @media (min-width: 1200px){  .hero-section .container .row h1{font-size: 58px!important}}</style><br/><a class=" btn rounded no-border btn-custom primary py-2 mt-4" href="/signup"><span>Signup Today!</span></a>',
          include_search_bar: "0",
          image: {
            ID: 1996,
            id: 1996,
            title: "for-companies-banner",
            filename: "for-companies-banner.png",
            filesize: 169674,
            url: Placeholder,
            link:
              "https://wdnexuswp.com/for-companies/attachment/for-companies-banner/",
            alt: "",
            author: "1",
            description: "",
            caption: "",
            name: "for-companies-banner",
            status: "inherit",
            uploaded_to: 218,
            date: "2022-07-22 10:14:36",
            modified: "2022-07-22 10:14:36",
            menu_order: 0,
            mime_type: "image/png",
            type: "image",
            subtype: "png",
            icon: "https://wdnexuswp.com/wp-includes/images/media/default.png",
            width: 444,
            height: 329,
            sizes: {
              thumbnail:
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-companies-banner-150x150.png",
              "thumbnail-width": 150,
              "thumbnail-height": 150,
              medium:
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-companies-banner-300x222.png",
              "medium-width": 300,
              "medium-height": 222,
              medium_large:
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-companies-banner.png",
              "medium_large-width": 444,
              "medium_large-height": 329,
              large:
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-companies-banner.png",
              "large-width": 444,
              "large-height": 329,
              "1536x1536":
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-companies-banner.png",
              "1536x1536-width": 444,
              "1536x1536-height": 329,
              "2048x2048":
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-companies-banner.png",
              "2048x2048-width": 444,
              "2048x2048-height": 329,
              "post-thumbnail":
                "https://wdnexuswp.com/wp-content/uploads/2022/07/for-companies-banner.png",
              "post-thumbnail-width": 444,
              "post-thumbnail-height": 329,
            },
          },
          cta_url: "",
          cta_text: "",
        },
        {
          acf_fc_layout: "three_column_content",
          title: "",
          description:
            "<style>.hero-section + .three-column-content h3{margin-bottom: 20px !important;}</style>\n<style> .job-step-counter{width: 40px; height: 40px; background-color: #4fb5df; border-radius: 50%; position: relative; display: inline-block; top: 10px; margin-right: 10px;} .job-step-counter span{position: absolute; font-size: 30px; color: #fff; top: -3px; left: 0; right: 0; bottom: 0;} </style>\n<style>.hero-section + .three-column-content .icon-container,.hero-section + .three-column-content .col-12.col{display: none;}</style>\n",
          column_content: [
            {
              column_imageicon: false,
              column_title:
                '<span class="job-step-counter"><span>1</span></span>Easy Signup',
              column_description: "<p>Quickly Register with your email.</p>\n",
            },
            {
              column_imageicon: false,
              column_title:
                '<span class="job-step-counter"><span>2</span></span>Search Candidates',
              column_description:
                "<p>Filter &amp; Approach right Candidates.</p>\n",
            },
            {
              column_imageicon: false,
              column_title:
                '<span class="job-step-counter"><span>3</span></span>Pay as you go',
              column_description: "<p>Only pay when you&#8217;re ready.</p>\n",
            },
          ],
        },
        {
          acf_fc_layout: "text_left_+_image_right",
          "text_+_image": [
            {
              title: "How WDN works for Companies?",
              text:
                "<p>Companies can quickly register into WDN and start looking for Candidate(s) based on the Company\u2019s requirement. Add CVs to your cart and pay a minimal fee for the CV based on the Candidate&#8217;s experience.</p>\n",
              button_text: "Create Free Account",
              button_url: "/signup/",
              image: {
                ID: 1498,
                id: 1498,
                title: "how-WDN-works-for-companies",
                filename: "how-WDN-works-for-companies.webp",
                filesize: 91448,
                url: Placeholder,
                link:
                  "https://wdnexuswp.com/for-companies/attachment/how-wdn-works-for-companies/",
                alt: "How WDN works for Companies",
                author: "1",
                description: "",
                caption: "",
                name: "how-wdn-works-for-companies",
                status: "inherit",
                uploaded_to: 218,
                date: "2022-01-21 06:11:09",
                modified: "2022-02-10 05:18:32",
                menu_order: 0,
                mime_type: "image/webp",
                type: "image",
                subtype: "webp",
                icon:
                  "https://wdnexuswp.com/wp-includes/images/media/default.png",
                width: 555,
                height: 410,
                sizes: {
                  thumbnail:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-companies-150x150.webp",
                  "thumbnail-width": 150,
                  "thumbnail-height": 150,
                  medium:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-companies-300x222.webp",
                  "medium-width": 300,
                  "medium-height": 222,
                  medium_large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-companies.webp",
                  "medium_large-width": 555,
                  "medium_large-height": 410,
                  large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-companies.webp",
                  "large-width": 555,
                  "large-height": 410,
                  "1536x1536":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-companies.webp",
                  "1536x1536-width": 555,
                  "1536x1536-height": 410,
                  "2048x2048":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-companies.webp",
                  "2048x2048-width": 555,
                  "2048x2048-height": 410,
                  "post-thumbnail":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/how-WDN-works-for-companies.webp",
                  "post-thumbnail-width": 555,
                  "post-thumbnail-height": 410,
                },
              },
            },
          ],
        },
        {
          acf_fc_layout: "image_left_+_text_right",
          "image_+_text": [
            {
              image: {
                ID: 1499,
                id: 1499,
                title: "discover-extraordinary-candidates",
                filename: "discover-extraordinary-candidates.webp",
                filesize: 13298,
                url: Placeholder,
                link:
                  "https://wdnexuswp.com/for-companies/attachment/discover-extraordinary-candidates/",
                alt: "Discover Extraordinary Candidates",
                author: "1",
                description: "",
                caption: "",
                name: "discover-extraordinary-candidates",
                status: "inherit",
                uploaded_to: 218,
                date: "2022-01-21 06:11:36",
                modified: "2022-02-10 05:18:24",
                menu_order: 0,
                mime_type: "image/webp",
                type: "image",
                subtype: "webp",
                icon:
                  "https://wdnexuswp.com/wp-includes/images/media/default.png",
                width: 621,
                height: 422,
                sizes: {
                  thumbnail:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/discover-extraordinary-candidates-150x150.webp",
                  "thumbnail-width": 150,
                  "thumbnail-height": 150,
                  medium:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/discover-extraordinary-candidates-300x204.webp",
                  "medium-width": 300,
                  "medium-height": 204,
                  medium_large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/discover-extraordinary-candidates.webp",
                  "medium_large-width": 621,
                  "medium_large-height": 422,
                  large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/discover-extraordinary-candidates.webp",
                  "large-width": 621,
                  "large-height": 422,
                  "1536x1536":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/discover-extraordinary-candidates.webp",
                  "1536x1536-width": 621,
                  "1536x1536-height": 422,
                  "2048x2048":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/discover-extraordinary-candidates.webp",
                  "2048x2048-width": 621,
                  "2048x2048-height": 422,
                  "post-thumbnail":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/discover-extraordinary-candidates.webp",
                  "post-thumbnail-width": 621,
                  "post-thumbnail-height": 422,
                },
              },
              title: " Discover Extraordinary Candidates.",
              text:
                "<p>WDN holds detailed information about Candidates. Choose the Candidate(s) you think would be perfect for the vacant job position(s).</p>\n",
              button_text: "Get Started Now!",
              button_url: "/signup/",
            },
          ],
        },
        {
          acf_fc_layout: "three_column_content",
          title: "Workers' Directory Nexus Difference",
          description:
            "<p>In WDN, Companies can do a quick or advanced search, filter results, and further move the hiring process with Candidate(s) matching your Company\u2019s requirements.</p>\n",
          column_content: [
            {
              column_imageicon: {
                ID: 1500,
                id: 1500,
                title: "find-the-right-match",
                filename: "find-the-right-match.webp",
                filesize: 2744,
                url: Placeholder,
                link:
                  "https://wdnexuswp.com/for-companies/attachment/find-the-right-match/",
                alt: "Find the Right Match",
                author: "1",
                description: "",
                caption: "",
                name: "find-the-right-match",
                status: "inherit",
                uploaded_to: 218,
                date: "2022-01-21 06:12:07",
                modified: "2022-02-10 05:18:10",
                menu_order: 0,
                mime_type: "image/webp",
                type: "image",
                subtype: "webp",
                icon:
                  "https://wdnexuswp.com/wp-includes/images/media/default.png",
                width: 89,
                height: 95,
                sizes: {
                  thumbnail:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-the-right-match.webp",
                  "thumbnail-width": 89,
                  "thumbnail-height": 95,
                  medium:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-the-right-match.webp",
                  "medium-width": 89,
                  "medium-height": 95,
                  medium_large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-the-right-match.webp",
                  "medium_large-width": 89,
                  "medium_large-height": 95,
                  large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-the-right-match.webp",
                  "large-width": 89,
                  "large-height": 95,
                  "1536x1536":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-the-right-match.webp",
                  "1536x1536-width": 89,
                  "1536x1536-height": 95,
                  "2048x2048":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-the-right-match.webp",
                  "2048x2048-width": 89,
                  "2048x2048-height": 95,
                  "post-thumbnail":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-the-right-match.webp",
                  "post-thumbnail-width": 89,
                  "post-thumbnail-height": 95,
                },
              },
              column_title: "Find the Right Match.",
              column_description:
                "<p>Our AI analyzes thousands of data points and finds the best matches for your requirements.</p>\n",
            },
            {
              column_imageicon: {
                ID: 1501,
                id: 1501,
                title: "save-time and-cost",
                filename: "save-time-and-cost.webp",
                filesize: 2478,
                url: Placeholder,
                link:
                  "https://wdnexuswp.com/for-companies/attachment/save-time-and-cost/",
                alt: "Save Time and Cost",
                author: "1",
                description: "",
                caption: "",
                name: "save-time-and-cost",
                status: "inherit",
                uploaded_to: 218,
                date: "2022-01-21 06:12:29",
                modified: "2022-02-10 05:18:02",
                menu_order: 0,
                mime_type: "image/webp",
                type: "image",
                subtype: "webp",
                icon:
                  "https://wdnexuswp.com/wp-includes/images/media/default.png",
                width: 95,
                height: 95,
                sizes: {
                  thumbnail:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/save-time-and-cost.webp",
                  "thumbnail-width": 95,
                  "thumbnail-height": 95,
                  medium:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/save-time-and-cost.webp",
                  "medium-width": 95,
                  "medium-height": 95,
                  medium_large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/save-time-and-cost.webp",
                  "medium_large-width": 95,
                  "medium_large-height": 95,
                  large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/save-time-and-cost.webp",
                  "large-width": 95,
                  "large-height": 95,
                  "1536x1536":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/save-time-and-cost.webp",
                  "1536x1536-width": 95,
                  "1536x1536-height": 95,
                  "2048x2048":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/save-time-and-cost.webp",
                  "2048x2048-width": 95,
                  "2048x2048-height": 95,
                  "post-thumbnail":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/save-time-and-cost.webp",
                  "post-thumbnail-width": 95,
                  "post-thumbnail-height": 95,
                },
              },
              column_title: "Save Time and Cost",
              column_description:
                "<p>WDN minimizes the time and cost that the Companies need to invest in hiring Candidate(s).</p>\n",
            },
            {
              column_imageicon: {
                ID: 1502,
                id: 1502,
                title: "find-candidates-faster",
                filename: "find-candidates-faster.webp",
                filesize: 3000,
                url: Placeholder,
                link:
                  "https://wdnexuswp.com/for-companies/attachment/find-candidates-faster/",
                alt: "Find Candidates Faster",
                author: "1",
                description: "",
                caption: "",
                name: "find-candidates-faster",
                status: "inherit",
                uploaded_to: 218,
                date: "2022-01-21 06:12:58",
                modified: "2022-02-10 05:17:47",
                menu_order: 0,
                mime_type: "image/webp",
                type: "image",
                subtype: "webp",
                icon:
                  "https://wdnexuswp.com/wp-includes/images/media/default.png",
                width: 95,
                height: 95,
                sizes: {
                  thumbnail:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-candidates-faster.webp",
                  "thumbnail-width": 95,
                  "thumbnail-height": 95,
                  medium:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-candidates-faster.webp",
                  "medium-width": 95,
                  "medium-height": 95,
                  medium_large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-candidates-faster.webp",
                  "medium_large-width": 95,
                  "medium_large-height": 95,
                  large:
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-candidates-faster.webp",
                  "large-width": 95,
                  "large-height": 95,
                  "1536x1536":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-candidates-faster.webp",
                  "1536x1536-width": 95,
                  "1536x1536-height": 95,
                  "2048x2048":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-candidates-faster.webp",
                  "2048x2048-width": 95,
                  "2048x2048-height": 95,
                  "post-thumbnail":
                    "https://wdnexuswp.com/wp-content/uploads/2022/01/find-candidates-faster.webp",
                  "post-thumbnail-width": 95,
                  "post-thumbnail-height": 95,
                },
              },
              column_title: "Find Candidates Faster",
              column_description:
                "<p>Sit on your desk, and start looking for Candidate(s) for your Company. Easy</p>\n",
            },
          ],
        },
        {
          acf_fc_layout: "cta_streak",
          background_color: "#2B337F",
          streak_content:
            '<p>Looking for experienced Candidates for your Company?\u00a0<a href="https://www.wdnexus.com/signup">Join Today.</a></p>\n',
        },
        {
          acf_fc_layout: "title_+_description",
          alignment: "0",
          title:
            'Any Questions? Checkout our <a href="/faqs" style="color: #4fb5df;">FAQS</a> for Common Q&A\'s and feel free to use our chat support 24X7 for any queries.',
          description: "",
        },
      ],
    },
    featured_image_src: {
      full:
        "https://wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
      large:
        "https://wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
      medium:
        "https://wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
      thumbnail:
        "https://wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
    },
    author_details: {
      id: 1,
      user_nicename: "codepros",
      user_url: "https://wdnexuswp.com/author/codepros/",
      firstname: "",
      lastname: "",
      display_name: "codepros",
    },
    published_on: "Jan 28, 2021",
    _links: {
      self: [
        {
          href: "https://wdnexuswp.com/wp-json/wp/v2/pages/218",
        },
      ],
      collection: [
        {
          href: "https://wdnexuswp.com/wp-json/wp/v2/pages",
        },
      ],
      about: [
        {
          href: "https://wdnexuswp.com/wp-json/wp/v2/types/page",
        },
      ],
      author: [
        {
          embeddable: true,
          href: "https://wdnexuswp.com/wp-json/wp/v2/users/1",
        },
      ],
      replies: [
        {
          embeddable: true,
          href: "https://wdnexuswp.com/wp-json/wp/v2/comments?post=218",
        },
      ],
      "version-history": [
        {
          count: 45,
          href: "https://wdnexuswp.com/wp-json/wp/v2/pages/218/revisions",
        },
      ],
      "predecessor-version": [
        {
          id: 2112,
          href: "https://wdnexuswp.com/wp-json/wp/v2/pages/218/revisions/2112",
        },
      ],
      "wp:attachment": [
        {
          href: "https://wdnexuswp.com/wp-json/wp/v2/media?parent=218",
        },
      ],
      curies: [
        {
          name: "wp",
          href: "https://api.w.org/{rel}",
          templated: true,
        },
      ],
    },
  },
]

export default forCompaniesPage
