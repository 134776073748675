import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import checkedImage from "../../images/light-check-mark.svg"
import "./newLandingPageBanner.scss"
import LandingPageFooter from "../landing-page-footer"
import myImage from "../../images/right-arrow.png"

export default function NewLandingPageBanner() {
  return (
    <section className="wdn-new new-banner">
      <div className="container">
        <div className="banner-content-wrapper position-relative">
          <div className="row align-items-center justify-content-xl-between ">
            <div className="col-lg-5  d-none d-lg-block">
              <div className="banner-content">
                <h1>
                  A simple and effective way to connect Companies with
                  Candidates
                </h1>
              </div>
            </div>

            <div className="col-lg-7 col-xl-6 offset-xl-1">
              <div className="signup-block d-flex align-items-start">
                <div className="sb-info d-none d-md-flex align-items-lg-center">
                  <div className="sbi-image">
                    <StaticImage
                      src="../../images/job-image.png"
                      alt=""
                      class="img-fluid"
                      placeholder="blurred"
                    />
                  </div>
                </div>
                <div className="pl-md-3">
                  <div className="sb-info d-flex align-items-lg-center">
                    <div className="sbi-content">
                      <h2 className="text-left">I’m a Jobseeker </h2>
                      <p>Searching for a new opportunity</p>
                    </div>
                  </div>
                  <div className="sb-btn-group d-flex justify-content-start ">
                    <Link to="/login" className="sized-outline sized-btn">
                      Login
                    </Link>
                    <Link
                      to="/signup?userType=2"
                      className="sized-filled sized-btn"
                    >
                      Sign Up
                    </Link>
                  </div>
                  <div className="sb-learnmore d-inline-block">
                    <Link to="/for-candidates">
                      Learn More
                      <span className="ml-2 mt-3">
                        <img
                          src={myImage}
                          alt=""
                          class=""
                          width={16}
                          height={16}
                        />
                        {"  "}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="signup-block d-flex align-items-start">
                <div className="sb-info d-none d-md-flex align-items-lg-center">
                  <div className="sbi-image">
                    <StaticImage
                      src="../../images/company-image.png"
                      alt=""
                      class="img-fluid"
                      placeholder="blurred"
                    />
                  </div>
                </div>
                <div className="pl-md-3">
                  <div className="sb-info d-flex align-items-lg-center">
                    <div className="sbi-content">
                      <h2 className="text-left">We’re a Company </h2>
                      <p>Searching for a new talent</p>
                    </div>
                  </div>
                  <div className="sb-btn-group d-flex justify-content-start ">
                    <Link to="/login" className="sized-outline sized-btn">
                      Login
                    </Link>
                    <Link
                      to="/signup?userType=3"
                      className="sized-filled sized-btn"
                    >
                      Sign Up
                    </Link>
                  </div>
                  <div className="sb-learnmore d-inline-block">
                    <Link to="/for-companies">
                      Learn More
                      <span className="ml-2 mt-3">
                        <img
                          src={myImage}
                          alt=""
                          class=""
                          width={16}
                          height={16}
                        />
                        {"  "}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LandingPageFooter classes={"d-none d-lg-block"} />
        </div>
      </div>
    </section>
  )
}
