import React, { useContext, useState } from "react"

import { NOTIFICATION_READ } from "../candidate-profile/notification/notifications-actions"
import { GlobalAuthContext } from "../../context/authContext"

import BlueEllipse from "../../images/icons/wdn-ellipse-blue.png"
import WhiteEllipse from "../../images/icons/wdn-ellipse-white.png"

import { formatDate, formatTime } from "../../utils/format-date-time"
import "./single-notification.scss"

const SingleNotification = ({ notification }) => {
  const {state, dispatch} = useContext(GlobalAuthContext)
  const [show, setShow] = useState(false)
  const [seen, setSeen] = useState(
    notification.read_at ? notification.read_at : false
  )

  const createMarkUp = data => {
    return { __html: data }
  }

  const toggleDetails = () => {
    if (!seen) {
      NOTIFICATION_READ(notification.id, setSeen, state, dispatch)
    }
    setShow(!show)
  }
  return (
    <div className="single-notification mb-2">
      <div className="row">
        <div className="col-lg-2 pt-2 pb-2 pb-lg-0">
          <div className="date">{formatDate(notification.created_at)}</div>
          <div className="time">{formatTime(notification.created_at)}</div>
        </div>
        <div className="d-none d-lg-block col-lg-1 col-1">
          <div className="notification-bulb-container">
            <img
              src={seen ? WhiteEllipse : BlueEllipse}
              alt="blue-ellipse"
              className="notification-bulb"
            />
          </div>
        </div>
        <div className="col-lg-9">
          <div
            className={seen ? "notification-text" : "notification-text unseen"}
          >
            <div
              className={
                show ? "notification-header mb-4" : "notification-header"
              }
            >
              <div className="row align-items-center">
                <div className="col-lg-9">
                  <div className="notification-title">{notification.title}</div>
                </div>
                <div className="col-lg-3 mt-2 mt-lg-0">
                  <button
                    onClick={toggleDetails}
                    className="view-more-less float-right"
                  >
                    {show ? "View Less" : "View Details"}
                  </button>
                </div>
              </div>
            </div>
            {show && (
              <div
                dangerouslySetInnerHTML={createMarkUp(notification.content)}
                className="notification-body"
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleNotification
