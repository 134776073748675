import React from "react"
import CreateNewJobForm from "../components/new-create-job-form"
import Header from "../components/new-home/header"

import "../components/new-home/global.scss"
import "../components/new-home/spacers.scss"

export default function CreateNewJob() {
  return (
    <CreateNewJobForm />
    // <>
    //   <Header />
    // </>
  )
}
