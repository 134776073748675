import React, { useContext, useEffect, useState } from "react"

import { GlobalAuthContext } from "../../context/authContext"

import DetailPage from "../../components/profile-detail/detail"
import ClientOnly from "../../components/client-only/client-only"
import { navigate } from "gatsby"
import Spinner from "../../components/spinner/spinner"

const CandidateDetailPage = ({ location }) => {
  const { state } = useContext(GlobalAuthContext)
  const [loading, setLoading] = useState(true)
  let username = location.pathname
  username = username.split("/")
  username = username[3]

  useEffect(() => {
    if (state?.isAuthenticated && state?.isEmailVerified) {
      setLoading(false)
    } else if (!localStorage?.token) {
      setLoading(false)
    } else if (state?.isAuthenticated && !state?.isEmailVerified) {
      navigate("/verify-email")
    }
  }, [state.isAuthenticated, state?.hasProfile]) // eslint-disable-line react-hooks/exhaustive-deps
  if (loading) return <Spinner />
  return (
    <ClientOnly>
      <DetailPage location={location} username={username} />
    </ClientOnly>
  )
}

export default CandidateDetailPage
