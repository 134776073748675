import React from "react"
import { Router } from "@reach/router"

import NotFoundPage from "../pages-sections/404"
import PageAssembler from "../pages-sections/page"
import BlogListingPage from "../components/blog/blog-list"
import BlogDetailPage from "../components/blog/blog-detail"
import ForgotPasswordPage from "../pages-sections/forgot-password"
import PasswordResetPage from "../pages-sections/reset-password"
import VerifyEmailPage from "../pages-sections/verify-email"
import CandidateDetailPage from "../pages-sections/candidate/detail"
import CompanyProfileDetailPage from "../pages-sections/company/detail"
import EducationPage from "../pages-sections/candidate/education"
import PersonalPage from "../pages-sections/candidate/personal"
import ProfessionalPage from "../pages-sections/candidate/professional"
import NotificationsPage from "../pages-sections/candidate/profile/notifications"
import CompanyNotificationPage from "../pages-sections/company/profile/notifications"
import StatisticsPage from "../pages-sections/candidate/profile/dashboard"
import SettingsPage from "../pages-sections/candidate/profile/settings/change-password"
import CheckOut from "../pages-sections/company/checkout"
import CompanyDetailPage from "../pages-sections/company/company-detail"
import CartPage from "../pages-sections/company/profile/cart"
import PaidCvsPage from "../pages-sections/company/profile/paid-cvs"
import ShortlistPage from "../pages-sections/company/profile/shortlist"
import CompanyStatisticsPage from "../pages-sections/company/profile/statistics"
import CompanySettingsPage from "../pages-sections/company/profile/settings/change-password"
import JobPostingPage from "../pages-sections/company/jobs"
import CompanyJobPosted from "../pages-sections/job-posted"
import JobDetailPage from "../pages-sections/job-detail"
import ApplicantsPage from "../pages-sections/company/applicants"
import NewHomePage from "../pages-sections/new-home"
import { NewSearchPage } from "../pages-sections/new-search-page"
import NewJobDetailPage from "../pages-sections/new-job-detail"
import CreateNewJob from "../pages-sections/new-create-job"
import NewLandingPage from "../pages-sections/new-landing-page"
import CandidateNewHome from "../pages-sections/candidate-new-home"
import NewPageLayout from "../components/layout/new-page-layout"

import HomeMeta from "../images/homeMeta.jpg"
import { navigate } from "gatsby"
import { GlobalAuthContext } from "../context/authContext"
import Spinner from "../components/spinner/spinner"
const Page = () => {
  const { state } = React.useContext(GlobalAuthContext)
  const [isLoading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const checkAuthentication = async () => {
      if (state.isAuthenticated) {
        if (state?.user?.candidate?.id) {
          navigate("/candidate/profile/dashboard")
        } else {
          navigate("/company/profile/dashboard")
        }
      }
    }
    checkAuthentication()
    setLoading(state.isAuthenticated)
  }, [state.isAuthenticated])

  if (isLoading) {
    return <Spinner />
  }
  return (
    <NewPageLayout
      title={"WDN - Search Connect Work"}
      description={`Workers’ Directory Nexus is a simple and effective way to connect Candidates with Companies.`}
      isCandidate={false}
      seoImage={HomeMeta}
      isHomePage={true}
    >
      <NewLandingPage />
    </NewPageLayout>
  )
  // <Router>
  //   {/* <NewHomePage exact path="/for-companies" /> */}
  //   {/* <NewSearchPage exact path="/new-search-page" /> */}
  //   <NewJobDetailPage exact path="/new-jobs/:slug" />
  //   {/* <CreateNewJob exact path="/create-new-job" /> */}

  //   {/* <CandidateNewHome exact path="/for-candidate" /> */}

  //   {/* <CheckOut exact path="/company/checkout" /> */}
  //   {/* <CompanyDetailPage exact path="/company/company-detail" /> */}
  //   {/* <CartPage exact path="/company/profile/cart" /> */}
  //   {/* <PaidCvsPage exact path="/company/profile/paid-cvs" /> */}
  //   {/* <ShortlistPage exact path="/company/profile/shortlist" /> */}
  //   {/* <CompanyNotificationPage exact path="/company/profile/notifications" /> */}
  //   {/* <CompanyStatisticsPage exact path="/company/profile/dashboard" /> */}
  //   {/* <CompanySettingsPage
  //     exact
  //     path="/company/profile/settings/change-password"
  //   /> */}
  //   <JobDetailPage exact path="/jobs/:slug" />
  //   <ApplicantsPage exact path="/jobs/:slug/applicants" />
  //   <ApplicantsPage exact path="/jobs/:slug/shortlist" isShortlist />
  //   <BlogDetailPage path="/blog/:slug" />
  //   <BlogListingPage path="/blog/category/:slug" />
  //   {/* <ForgotPasswordPage exact path="/forgot-password" /> */}
  //   {/* <PasswordResetPage exact path="/reset-password" /> */}
  //   {/* <VerifyEmailPage exact path="/verify-email" /> */}
  //   <CandidateDetailPage exact path="/candidate/detail/:username" />
  //   <CompanyProfileDetailPage exact path="/company/detail/:username" />
  //   {/* <EducationPage exact path="/candidate/education" />
  //   <PersonalPage exact path="/candidate/personal" /> */}
  //   {/* <ProfessionalPage exact path="/candidate/professional" /> */}
  //   {/* <NotificationsPage exact path="/candidate/profile/notifications" />
  //   <StatisticsPage exact path="/candidate/profile/dashboard" />
  //   <SettingsPage exact path="/candidate/profile/settings/change-password" /> */}
  //   {/* <NotFoundPage exact path="/404" /> */}
  //   {/* <JobPostingPage exact path="/jobs/post-job" /> */}
  //   <JobPostingPage exact path="/jobs/post-job/:slug" />
  //   {/* <CompanyJobPosted exact path="/jobs" /> */}
  //   <PageAssembler exact path="/:slug" />
  //   <PageAssembler exact path="/" />
  //   <NotFoundPage default />
  // </Router>
}

export default Page
