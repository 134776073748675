import React, { useContext, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"

import "./job-detail.scss"
import JobStatistics from "./job-statistics"
import renderElement from "../../../utils/renderElement"
import { GlobalAuthContext } from "../../../context/authContext"
import { CANDIDATE_APPLY_JOB, CANDIDATE_SAVE_JOB } from "../job-actions"
import { navigate } from "gatsby"

const JobDetail = ({ job_data, setData }) => {
  const [isSaved, setSaved] = useState(
    job_data?.saved_status ? "Saved" : "Save"
  )

  const { state } = useContext(GlobalAuthContext)

  const handleApplyClick = async () => {
    if (!state?.isAuthenticated) {
      navigate("/login")
      return
    }
    const data = {
      candidate_id: state?.user?.id,
      job_id: job_data?.id,
    }
    const res = await CANDIDATE_APPLY_JOB(data)
    if (res?.status === 201) {
      setData({
        ...job_data,
        applicants: parseInt(job_data?.applicants) + 1,
        applied_status: true,
      })
    }
  }
  const handleSaveClick = async () => {
    setSaved("Processing")
    const data = {
      candidate_id: state?.user?.id,
      job_id: job_data?.id,
    }
    const res = await CANDIDATE_SAVE_JOB(data)
    if (res?.status === 201) {
      setData({ ...job_data, shortlisted: parseInt(job_data?.shortlisted) + 1 })
      setSaved("Saved")
    } else {
      setSaved("Save")
    }
  }

  return (
    <section className="job-detail mb-4">
      <Container>
        <Row>
          <Col lg="7" className="offset-lg-1 order-12 order-lg-1">
            <h4 className="mb-4">Job Details</h4>
            <div className="title-desc d-flex align-items-center mb-4">
              <div className="image">
                <img
                  src={job_data?.company?.avatar}
                  className="p-1 border"
                  alt=""
                />
              </div>
              <div className="title pl-4">
                {job_data?.title && <h3>{job_data?.title}</h3>}
                {job_data?.company?.organization_name && (
                  <p>{job_data?.company?.organization_name}</p>
                )}
              </div>
            </div>
            <div className="description">
              <Row className="mb-4">
                <Col lg="6">
                  <p className="fw-normal">Experience:</p>
                </Col>
                <Col lg="6">
                  <p className="black">{job_data?.experience}</p>
                </Col>
                <Col lg="6">
                  <p className="fw-normal">Approximate Salary:</p>
                </Col>
                <Col lg="6">
                  <p className="black">{job_data?.salary}</p>
                </Col>
                <Col lg="6">
                  <p className="fw-normal">Education:</p>
                </Col>
                <Col lg="6">
                  <p className="black">{job_data?.education_level}</p>
                </Col>
                <Col lg="6">
                  <p className="fw-normal">No. of Vacancies:</p>
                </Col>
                <Col lg="6">
                  <p className="black">{job_data?.no_of_employees}</p>
                </Col>
                <Col lg="6">
                  <p className="fw-normal">Preferred Gender:</p>
                </Col>
                <Col lg="6">
                  <p className="black">{job_data?.gender?.name}</p>
                </Col>
                <Col lg="6">
                  <p className="fw-normal">Preferred Location(s):</p>
                </Col>
                <Col lg="6">
                  <p className="black">{job_data?.preferred_location}</p>
                </Col>
                <Col lg="6">
                  <p className="fw-normal">Work Type:</p>
                </Col>
                <Col lg="6">
                  <p className="black">{job_data?.job_type}</p>
                </Col>
                <Col lg="6">
                  <p className="fw-normal">Work Shift:</p>
                </Col>
                <Col lg="6">
                  <p className="black">{job_data?.job_shift}</p>
                </Col>
                <Col lg="6">
                  <p className="fw-normal">Address:</p>
                </Col>
                <Col lg="6">
                  <p className="black">{job_data?.address}</p>
                </Col>
              </Row>
              {job_data?.skills?.length > 0 && (
                <Row className="mb-4">
                  <Col>
                    <p className="black mb-0">Skills:</p>
                    <ul className="skills">
                      {job_data.skills.map(skill => {
                        return (
                          <li key={skill?.id}>
                            <span>{skill?.name}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </Col>
                </Row>
              )}
              <Row className="mb-4">
                <Col>
                  <p className="black">Job Description:</p>
                  <div
                    className="job-description"
                    dangerouslySetInnerHTML={renderElement(
                      job_data?.job_description
                        ? job_data?.job_description
                        : "No description available."
                    )}
                  ></div>
                  <div className="pb-4 border-bottom"></div>
                </Col>
              </Row>
              {!state?.user?.company?.id && (
                <Row className="text-right">
                  {false ? (
                    <Col lg={{ span: "4", offset: "4" }}>
                      <button
                        disabled={
                          isSaved === "Processing" || isSaved === "Saved"
                        }
                        onClick={handleSaveClick}
                        className=" btn  blue-outline rounded px-4 w-100 mb-lg-0 mb-2"
                      >
                        {isSaved}
                      </button>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col lg={{ span: "4", offset: "8" }}>
                    <button
                      onClick={handleApplyClick}
                      disabled={job_data?.applied_status}
                      className="blue-background btn  blue-outline rounded px-4 w-100"
                    >
                      {job_data?.applied_status ? "Applied" : "Apply"}
                    </button>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
          <Col lg="3" md="8" className="order-1 order-lg-12">
            <JobStatistics job_data={job_data} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default JobDetail
