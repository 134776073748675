import React, { useState } from "react"

import { Link, navigate } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import endpoints from "../../constants/endpoints"
import { WDN } from "../../services/axiosInstance"

import Warning from "../../images/icons/wdn-warning.png"

import "./password-reset.scss"
import InputTextBox from "../input/input-text-box"
import BreadcrumbComponent from "../breadcrumb/breadcrum"

const PasswordReset = ({ token }) => {
  const [error, setError] = useState({})
  const [message, setMessage] = useState({})
  const [password, setPassword] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("")

  const pages = [{ name: "Home", link: "/" }]
  const currentPage = { name: "Reset Password" }

  const handleConfirmPasswordInputChange = confirmPassword => {
    setConfirmPassword(confirmPassword)
  }

  const handlePasswordInputChange = password => {
    setPassword(password)
  }

  const handleInputFocus = () => {
    setError({})
  }

  const handleResetPasswordSubmitForm = async e => {
    e.preventDefault()
    setLoading(true)
    setError({})
    if (password !== confirmPassword) {
      setError({ confirmPassword: "Password mis-match" })
    } else {
      const data = {
        password,
        token,
      }
      try {
        const res = await WDN.post(endpoints.RESET_PASSWORD_ROUTE, data)
        if (res.status === 200) {
          setMessage({
            success: "Congratulations!, password has been reset successfully",
          })
          setTimeout(() => {
            navigate("/login")
          }, 5000)
        }
      } catch (err) {
        if (err.response.status === 404) {
          setError({
            failed:
              "Token is not valid, you'll be redirected to forgot password page please proceed again",
          })
          setTimeout(() => {
            navigate("/forgot-password")
          }, 5000)
        } else {
          setError(err.response.data)
        }
      }
    }
    setLoading(false)
  }

  return (
    <>
      <BreadcrumbComponent pages={pages} currentPage={currentPage} />
      <section className="password-reset-page">
        <Container>
          <Row className="">
            <Col lg="6 m-auto" md="10" sm="10">
              <div className="form-container">
                <div className="form-title">
                  <h1> Reset Password</h1>
                  <p>Enter your new password below.</p>
                </div>
                <form onSubmit={handleResetPasswordSubmitForm}>
                  <InputTextBox
                    name="password"
                    placeholder="Password"
                    label="new password"
                    onChange={handlePasswordInputChange}
                    onFocus={handleInputFocus}
                    class={error && error.password && `error`}
                    value={password}
                    type="password"
                  />
                  <InputTextBox
                    name="confirm password"
                    placeholder="Confirm Password"
                    label="confirm password"
                    onFocus={handleInputFocus}
                    onChange={handleConfirmPasswordInputChange}
                    value={confirmPassword}
                    class={error && error.confirmPassword && `error`}
                    type="password"
                  />
                  {error.confirmPassword && (
                    <div className="alert-block mb-4 d-flex align-items-center">
                      <img src={Warning} className="mr-3" alt="wdn-warning" />
                      {error.confirmPassword}
                    </div>
                  )}
                  {error.password && (
                    <div className="alert-block mb-4 form-control d-flex align-items-center">
                      <img src={Warning} alt="wdn-warning" className="mr-3" />
                      {error.password}
                    </div>
                  )}

                  {error.failed && (
                    <div className="alert-block mb-4 form-control d-flex align-items-center">
                      <img src={Warning} alt="wdn-warning" className="mr-3" />
                      {error.failed}
                    </div>
                  )}

                  {message.success && (
                    <div className="alert-block mb-4 form-control d-flex align-items-center">
                      <img src={Warning} alt="wdn-warning" className="mr-3" />
                      {message.success}
                    </div>
                  )}
                  <div className="form-group">
                    <input
                      type="submit"
                      disabled={isLoading}
                      className="btn-custom primary w-100 custom-border rounded "
                      value={isLoading ? `Processing` : `Submit`}
                    />
                  </div>
                </form>
                <div>
                  <Link
                    to="/login"
                    className="btn btn-custom d-block border w-100 rounded  uncapitalize "
                  >
                    Back to login
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default PasswordReset
