import React, { useState } from "react"

import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import { WDN } from "../../services/axiosInstance"
import endpoints from "../../constants/endpoints"

import Warning from "../../images/icons/wdn-warning.png"

import "./forgot-password.scss"
import InputTextBox from "../input/input-text-box"
import BreadcrumbComponent from "../breadcrumb/breadcrum"

const ForgotPassword = () => {
  const [error, setError] = useState({})
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState({})
  const [isLoading, setLoading] = useState(false)

  const pages = [{ name: "Home", link: "/" }]
  const currentPage = { name: "Forgot Password" }

  const handleEmailInputChange = email => {
    setEmail(email)
  }

  const handleForgotPasswordFormSubmit = async e => {
    setLoading(true)
    setError({})
    setMessage({})
    e.preventDefault()
    const data = {
      email: email,
    }
    try {
      const res = await WDN.post(endpoints.FORGOT_PASSWORD_ROUTE, data)
      if ((res.status = 200)) {
        setMessage({
          success:
            "An email with instruction to reset your password has been sent to your email.",
        })
      }
    } catch (err) {
      setError(err.response.data)
    }
    setLoading(false)
  }

  const handleInputFocus = () => {
    setError({})
    setMessage({})
  }

  return (
    <>
      <BreadcrumbComponent pages={pages} currentPage={currentPage} />
      <section className="forgot-password-page">
        <Container>
          <Row className="">
            <Col lg="6 m-auto" md="10" sm="10">
              <div className="form-container">
                <div className="form-title">
                  <h1> Forgot Password</h1>
                  <p>
                   Enter your registered email address.
                  </p>
                </div>

                <form onSubmit={handleForgotPasswordFormSubmit}>
                  <InputTextBox
                    name="email"
                    placeholder="example@email.com"
                    label="email"
                    onFocus={handleInputFocus}
                    onChange={handleEmailInputChange}
                    value={email}
                    class={error && error.email && `error`}
                    type="text"
                  />

                  {error.email && (
                    <div className="alert-block mb-4 form-control d-flex align-items-center">
                      <img src={Warning} className="mr-3" alt="wdn-warning" />
                      {error.email}
                    </div>
                  )}

                  {message.success && (
                    <div className="alert-block mb-4 form-control d-flex align-items-center">
                      <img src={Warning} alt="wdn-warning" className="mr-3" />
                      {message.success}
                    </div>
                  )}
                  <div className="form-group">
                    <input
                      type="submit"
                      disabled={isLoading}
                      className="btn-custom primary w-100 custom-border rounded "
                      value={isLoading ? `Processing` : `Submit`}
                    />
                  </div>
                </form>
                <div>
                  <Link
                    to="/login"
                    className="btn btn-custom d-block border w-100 rounded  uncapitalize "
                  >
                    Back to login
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ForgotPassword
