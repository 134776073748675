import Placeholder from "../../images/placeholder.jpg"

const homeData = [
  {
    id: 372,
    date: "2021-04-07T04:15:07",
    date_gmt: "2021-04-07T04:15:07",
    modified: "2022-07-21T10:40:21",
    modified_gmt: "2022-07-21T10:40:21",
    slug: "home",
    status: "publish",
    type: "page",
    title: {
      rendered: "Home",
    },
    content: {
      rendered: "",
      protected: false,
    },
    excerpt: {
      rendered: "",
      protected: false,
    },
    author: 1,
    featured_media: 0,
    parent: 0,
    menu_order: 0,
    comment_status: "closed",
    ping_status: "closed",
    template: "",
    meta: [],
    acf: {
      seo_metadata: [
        {
          title: "WDN - Search Connect Work",
          meta_keywords:
            "Home WDN, Connect Candidates with Companies, Find A Job In Nepal, Hiring Companies Near Me, Candidates Job Market, Low Cost Job Market, Companies Job Vacancies",
          description:
            "Workers\u2019 Directory Nexus is a simple and effective way to connect Candidates with Companies.",
          meta_image: Placeholder,
          facebook_title: "WDN - Search, Connect, Work",
          facebook_description:
            "Workers\u2019 Directory Nexus is a simple and effective way to connect Candidates with Companies.",
          facebook_image: Placeholder,
          twitter_title: "WDN - Search, Connect, Work",
          twitter_description:
            "Workers\u2019 Directory Nexus is a simple and effective way to connect Candidates with Companies.",
          twitter_image: Placeholder,
        },
      ],
      content: [
        {
          acf_fc_layout: "banner",
          background_color: "#edf7fc",
          title:
            'Hire Candidates <span style="color:#2b3480">Instantly</span> <style>.hero-section .container .row .hero__input-box select{border: 1px solid grey !important} @media (max-width: 767px){.hero-section{padding: 2rem 0;} .hero-section .container .row .order-lg-last img{width: 75%;}}  .hero-section .container .row h1{font-size: 40px!important;} @media (min-width: 1200px){ .hero-section .container .row h1{font-size: 58px!important}}</style>',
          include_search_bar: "1",
          image: {
            ID: 1964,
            id: 1964,
            title: "hire-candidates-instantly-wdn",
            filename: "hire-candidates-instantly-wdn.png",
            filesize: 250907,
            url: Placeholder,

            alt: "Hire Candidates Instantly - WDN",
            author: "1",
            description: "",
            caption: "",
            name: "hire-candidates-instantly-wdn",
            status: "inherit",
            uploaded_to: 1778,
            date: "2022-07-21 10:01:43",
            modified: "2022-07-21 10:02:03",
            menu_order: 0,
            mime_type: "image/png",
            type: "image",
            subtype: "png",
            icon: Placeholder,
            width: 587,
            height: 466,
          },
          cta_url: "https://dev.wdnexuswp.com/about/",
          cta_text: "Find Candidates",
        },
        {
          acf_fc_layout: "image_left_+_text_right",
          "image_+_text": [
            {
              image: {
                ID: 1973,
                id: 1973,
                title: "one-day-hiring",
                filename: "one-day-hiring.png",
                filesize: 116787,
                url: Placeholder,

                alt: "One Day Hiring",
                author: "1",
                description: "",
                caption: "",
                name: "one-day-hiring-2",
                status: "inherit",
                uploaded_to: 372,
                date: "2022-07-21 10:11:56",
                modified: "2022-07-21 10:12:21",
                menu_order: 0,
                mime_type: "image/png",
                type: "image",
                subtype: "png",
                icon:
                  "https://dev.wdnexuswp.com/wp-includes/images/media/default.png",
                width: 1030,
                height: 903,
                sizes: {
                  thumbnail:
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/one-day-hiring-150x150.png",
                  "thumbnail-width": 150,
                  "thumbnail-height": 150,
                  medium:
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/one-day-hiring-300x263.png",
                  "medium-width": 300,
                  "medium-height": 263,
                  medium_large:
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/one-day-hiring-768x673.png",
                  "medium_large-width": 750,
                  "medium_large-height": 657,
                  large:
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/one-day-hiring-1024x898.png",
                  "large-width": 750,
                  "large-height": 658,
                  "1536x1536":
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/one-day-hiring.png",
                  "1536x1536-width": 1030,
                  "1536x1536-height": 903,
                  "2048x2048":
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/one-day-hiring.png",
                  "2048x2048-width": 1030,
                  "2048x2048-height": 903,
                  "post-thumbnail":
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/one-day-hiring.png",
                  "post-thumbnail-width": 1030,
                  "post-thumbnail-height": 903,
                },
              },
              title:
                '<p class="mb-1 uppercase" style="letter-spacing: 1px; font-size: 14px;">--- For Companies ---</p><font color="#4fb5df">One Day</font> hiring!',
              text:
                "<p><strong>Search and Checkout Candidates&#8217; profile</strong> and approach them within minutes. One day recruitment are now possible.</p>\n",
              button_text: "Learn More",
              button_url: "/for-companies",
            },
          ],
        },
        {
          acf_fc_layout: "text_left_+_image_right",
          "text_+_image": [
            {
              title:
                '<p class="mb-1 uppercase" style="letter-spacing: 1px; font-size: 14px;">--- For Candidates ---</p>Tired <font color="#4fb5df">Applying</font> for Jobs?',
              text:
                "<p><strong>Create and complete your profile</strong> and let us instantly bring your profile to Companies&#8217; reach.</p>\n",
              button_text: "Learn More",
              button_url: "/for-candidates",
              image: {
                ID: 1975,
                id: 1975,
                title: "tired-applying-for-jobs",
                filename: "tired-applying-for-jobs.png",
                filesize: 642155,
                url: Placeholder,
                link:
                  "https://dev.wdnexuswp.com/home/attachment/tired-applying-for-jobs-2/",
                alt: "",
                author: "1",
                description: "",
                caption: "",
                name: "tired-applying-for-jobs-2",
                status: "inherit",
                uploaded_to: 372,
                date: "2022-07-21 10:14:31",
                modified: "2022-07-21 10:14:31",
                menu_order: 0,
                mime_type: "image/png",
                type: "image",
                subtype: "png",
                icon:
                  "https://dev.wdnexuswp.com/wp-includes/images/media/default.png",
                width: 827,
                height: 698,
                sizes: {
                  thumbnail:
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/tired-applying-for-jobs-150x150.png",
                  "thumbnail-width": 150,
                  "thumbnail-height": 150,
                  medium:
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/tired-applying-for-jobs-300x253.png",
                  "medium-width": 300,
                  "medium-height": 253,
                  medium_large:
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/tired-applying-for-jobs-768x648.png",
                  "medium_large-width": 750,
                  "medium_large-height": 633,
                  large:
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/tired-applying-for-jobs.png",
                  "large-width": 750,
                  "large-height": 633,
                  "1536x1536":
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/tired-applying-for-jobs.png",
                  "1536x1536-width": 827,
                  "1536x1536-height": 698,
                  "2048x2048":
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/tired-applying-for-jobs.png",
                  "2048x2048-width": 827,
                  "2048x2048-height": 698,
                  "post-thumbnail":
                    "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/tired-applying-for-jobs.png",
                  "post-thumbnail-width": 827,
                  "post-thumbnail-height": 698,
                },
              },
            },
          ],
        },
        // {
        //   acf_fc_layout: "three_column_content",
        //   title: "Trending Articles",
        //   description: "<style>.icon-container img{max-width: 100%;}</style>\n",
        //   column_content: [
        //     {
        //       column_imageicon: {
        //         ID: 1893,
        //         id: 1893,
        //         title: "employee-engagement-and-commitment",
        //         filename: "employee-engagement-and-commitment.webp",
        //         filesize: 30962,
        //         url: Placeholder,
        //         link:
        //           "https://dev.wdnexuswp.com/home/attachment/employee-engagement-and-commitment-3/",
        //         alt: "",
        //         author: "1",
        //         description: "",
        //         caption: "",
        //         name: "employee-engagement-and-commitment-3",
        //         status: "inherit",
        //         uploaded_to: 372,
        //         date: "2022-07-18 10:17:05",
        //         modified: "2022-07-18 10:17:05",
        //         menu_order: 0,
        //         mime_type: "image/webp",
        //         type: "image",
        //         subtype: "webp",
        //         icon:
        //           "https://dev.wdnexuswp.com/wp-includes/images/media/default.png",
        //         width: 1000,
        //         height: 1000,
        //         sizes: {
        //           thumbnail:
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/employee-engagement-and-commitment-150x150.webp",
        //           "thumbnail-width": 150,
        //           "thumbnail-height": 150,
        //           medium:
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/employee-engagement-and-commitment-300x300.webp",
        //           "medium-width": 300,
        //           "medium-height": 300,
        //           medium_large:
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/employee-engagement-and-commitment-768x768.webp",
        //           "medium_large-width": 750,
        //           "medium_large-height": 750,
        //           large:
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/employee-engagement-and-commitment.webp",
        //           "large-width": 750,
        //           "large-height": 750,
        //           "1536x1536":
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/employee-engagement-and-commitment.webp",
        //           "1536x1536-width": 1000,
        //           "1536x1536-height": 1000,
        //           "2048x2048":
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/employee-engagement-and-commitment.webp",
        //           "2048x2048-width": 1000,
        //           "2048x2048-height": 1000,
        //           "post-thumbnail":
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/employee-engagement-and-commitment.webp",
        //           "post-thumbnail-width": 1000,
        //           "post-thumbnail-height": 1000,
        //         },
        //       },
        //       column_title: "Employee Engagement and Commitment",
        //       column_description:
        //         '<p>A committed employee is satisfied and knows the organizational philosophy in depth. On the contrary, employee engagement involves the employee making voluntary attempts to achieve organizational goals.\u00a0 <span style="color: #4fb5e0;"><a style="color: #4fb5e0;" href="https://www.wdnexus.com/blog/employee-engagement-and-commitment">See More</a></span></p>\n',
        //     },
        //     {
        //       column_imageicon: {
        //         ID: 1894,
        //         id: 1894,
        //         title: "do-you-feel-overworked-and-undervalued",
        //         filename: "do-you-feel-overworked-and-undervalued.webp",
        //         filesize: 24616,
        //         url: Placeholder,
        //         link:
        //           "https://dev.wdnexuswp.com/home/attachment/do-you-feel-overworked-and-undervalued-3/",
        //         alt: "",
        //         author: "1",
        //         description: "",
        //         caption: "",
        //         name: "do-you-feel-overworked-and-undervalued-3",
        //         status: "inherit",
        //         uploaded_to: 372,
        //         date: "2022-07-18 10:19:53",
        //         modified: "2022-07-18 10:19:53",
        //         menu_order: 0,
        //         mime_type: "image/webp",
        //         type: "image",
        //         subtype: "webp",
        //         icon:
        //           "https://dev.wdnexuswp.com/wp-includes/images/media/default.png",
        //         width: 1000,
        //         height: 1000,
        //         sizes: {
        //           thumbnail:
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/do-you-feel-overworked-and-undervalued-150x150.webp",
        //           "thumbnail-width": 150,
        //           "thumbnail-height": 150,
        //           medium:
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/do-you-feel-overworked-and-undervalued-300x300.webp",
        //           "medium-width": 300,
        //           "medium-height": 300,
        //           medium_large:
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/do-you-feel-overworked-and-undervalued-768x768.webp",
        //           "medium_large-width": 750,
        //           "medium_large-height": 750,
        //           large:
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/do-you-feel-overworked-and-undervalued.webp",
        //           "large-width": 750,
        //           "large-height": 750,
        //           "1536x1536":
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/do-you-feel-overworked-and-undervalued.webp",
        //           "1536x1536-width": 1000,
        //           "1536x1536-height": 1000,
        //           "2048x2048":
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/do-you-feel-overworked-and-undervalued.webp",
        //           "2048x2048-width": 1000,
        //           "2048x2048-height": 1000,
        //           "post-thumbnail":
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/do-you-feel-overworked-and-undervalued.webp",
        //           "post-thumbnail-width": 1000,
        //           "post-thumbnail-height": 1000,
        //         },
        //       },
        //       column_title: "Do You Feel Overworked And Undervalued?",
        //       column_description:
        //         '<p>Working for a company that does not value your efforts can grow you restless. You cannot change the way your company treats you. But you can definitely change your company. <span style="color: #4fb5e0;"><a style="color: #4fb5e0;" href="https://www.wdnexus.com/blog/do-you-feel-overworked-and-undervalued">See More</a></span></p>\n',
        //     },
        //     {
        //       column_imageicon: {
        //         ID: 1957,
        //         id: 1957,
        //         title: "hr-interview-questions-and-answers",
        //         filename: "hr-interview-questions-and-answers.webp",
        //         filesize: 64036,
        //         url: Placeholder,
        //         link:
        //           "https://dev.wdnexuswp.com/human-resources/hr-interview-questions-and-answers/attachment/hr-interview-questions-and-answers-2/",
        //         alt: "HR Interview Questions and Answers",
        //         author: "1",
        //         description: "",
        //         caption: "",
        //         name: "hr-interview-questions-and-answers-2",
        //         status: "inherit",
        //         uploaded_to: 1941,
        //         date: "2022-07-19 16:26:13",
        //         modified: "2022-07-19 16:27:09",
        //         menu_order: 0,
        //         mime_type: "image/webp",
        //         type: "image",
        //         subtype: "webp",
        //         icon:
        //           "https://dev.wdnexuswp.com/wp-includes/images/media/default.png",
        //         width: 1000,
        //         height: 1000,
        //         sizes: {
        //           thumbnail:
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/hr-interview-questions-and-answers-150x150.webp",
        //           "thumbnail-width": 150,
        //           "thumbnail-height": 150,
        //           medium:
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/hr-interview-questions-and-answers-300x300.webp",
        //           "medium-width": 300,
        //           "medium-height": 300,
        //           medium_large:
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/hr-interview-questions-and-answers-768x768.webp",
        //           "medium_large-width": 750,
        //           "medium_large-height": 750,
        //           large:
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/hr-interview-questions-and-answers.webp",
        //           "large-width": 750,
        //           "large-height": 750,
        //           "1536x1536":
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/hr-interview-questions-and-answers.webp",
        //           "1536x1536-width": 1000,
        //           "1536x1536-height": 1000,
        //           "2048x2048":
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/hr-interview-questions-and-answers.webp",
        //           "2048x2048-width": 1000,
        //           "2048x2048-height": 1000,
        //           "post-thumbnail":
        //             "https://dev.wdnexuswp.com/wp-content/uploads/2022/07/hr-interview-questions-and-answers.webp",
        //           "post-thumbnail-width": 1000,
        //           "post-thumbnail-height": 1000,
        //         },
        //       },
        //       column_title: "HR Interview Questions and Answers ",
        //       column_description:
        //         '<p>Interviews can be hectic if you go without preparation. Prepare yourself with these highly asked HR interview questions and answers. <span style="color: #4fb5e0;"><a style="color: #4fb5e0;" href="https://wdnexus.com/blog/hr-interview-questions-and-answers" rel="noopener">See More</a></span></p>\n',
        //     },
        //   ],
        // },
      ],
    },
    featured_image_src: {
      full:
        "https://dev.wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
      large:
        "https://dev.wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
      medium:
        "https://dev.wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
      thumbnail:
        "https://dev.wdnexuswp.com/wp-content/plugins/wp-rest-api-helper/assets/img/placeholder.png",
    },
    author_details: {
      id: 1,
      user_nicename: "codepros",
      user_url: "https://dev.wdnexuswp.com/author/codepros/",
      firstname: "",
      lastname: "",
      display_name: "codepros",
    },
    published_on: "Apr 7, 2021",
    _links: {
      self: [
        {
          href: "https://dev.wdnexuswp.com/wp-json/wp/v2/pages/372",
        },
      ],
      collection: [
        {
          href: "https://dev.wdnexuswp.com/wp-json/wp/v2/pages",
        },
      ],
      about: [
        {
          href: "https://dev.wdnexuswp.com/wp-json/wp/v2/types/page",
        },
      ],
      author: [
        {
          embeddable: true,
          href: "https://dev.wdnexuswp.com/wp-json/wp/v2/users/1",
        },
      ],
      replies: [
        {
          embeddable: true,
          href: "https://dev.wdnexuswp.com/wp-json/wp/v2/comments?post=372",
        },
      ],
      "version-history": [
        {
          count: 207,
          href: "https://dev.wdnexuswp.com/wp-json/wp/v2/pages/372/revisions",
        },
      ],
      "predecessor-version": [
        {
          id: 1993,
          href:
            "https://dev.wdnexuswp.com/wp-json/wp/v2/pages/372/revisions/1993",
        },
      ],
      "wp:attachment": [
        {
          href: "https://dev.wdnexuswp.com/wp-json/wp/v2/media?parent=372",
        },
      ],
      curies: [
        {
          name: "wp",
          href: "https://api.w.org/{rel}",
          templated: true,
        },
      ],
    },
  },
]

export default homeData
