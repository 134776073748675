import React from "react"

import { Row, Col } from "react-bootstrap"

import SingleNotification from "./single-notification"
import Paginator from "../paginator/paginator"
import "./notifications.scss"

const Notifications = ({ notifications, currentPage, setCurrentPage }) => {
  return (
    <>
      <ul
        className={
          notifications.total_pages > 1
            ? `border-bottom notifications-container  pb-2 pb-md-5`
            : `notifications-container  pb-2 pb-md-5`
        }
      >
        {notifications &&
          notifications.results &&
          notifications.results.map((singleNotification, index) => (
            <li key={singleNotification.id}>
              <SingleNotification
                key={index}
                notification={singleNotification}
              />
            </li>
          ))}
        {!notifications.results ||
          (notifications.results.length < 1 && (
            <li>
              <Row>
                <Col>You currently don't have any notifications.</Col>
              </Row>
            </li>
          ))}
      </ul>
      {notifications.total_pages > 1 && (
        <div className="float-right mt-4">
          <Paginator
            pageCount={notifications.total_pages}
            pageRangeDisplayed={2}
            selected={currentPage}
            setCurrentPage={setCurrentPage}
            initialPage={currentPage}
            marginPagesDisplayed={2}
          />
        </div>
      )}
    </>
  )
}
export default Notifications
