import React from "react"
import Header from "../components/new-home/header"
import LandingPageFooter from "../components/landing-page-footer"

import "../components/new-home/global.scss"
import "../components/new-home/spacers.scss"
import NewLandingPageBanner from "../components/new-landing-page-banner"

export default function NewLandingPage() {
  return (
    <NewLandingPageBanner />
    // <>
    //   <Header isCandidate={false} />
    //   <LandingPageFooter />
    // </>
  )
}
