import React, { useContext, useEffect, useState } from "react"

import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import ForgotPassword from "../components/forgot-password/forgot-password"
import { GlobalAuthContext } from "../context/authContext"
import { Spinner } from "react-bootstrap"
import { navigate } from "gatsby"

const ForgotPasswordPage = () => {
  const [loading, setLoading] = useState(true)
  const { state } = useContext(GlobalAuthContext)
  useEffect(() => {
    if (!localStorage?.token) {
      setLoading(false)
    } else {
      navigate("/")
    }
  }, [state?.isAuthenticated, state?.hasProfile])
  if (loading) return <Spinner />
  return (
    <Layout noVerification>
      <SEO title="Forgot Password" robot_no_index />
      <ForgotPassword />
    </Layout>
  )
}

export default ForgotPasswordPage
